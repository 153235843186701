import { Component, OnInit } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { ProfileService } from '../../services/helpers/profile.service';
import { UrlSerice } from '../../services/helpers/url-service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  previousURL: string = location.origin;
  previousURLLoaded: boolean = false;

  constructor(private urlService: UrlSerice, private profileService: ProfileService) { }

  ngOnInit(): void {
    let notFoundLink: string = this.profileService.getNotFoundLink();
    let urlSegments: UrlSegment[] = this.urlService.getPreviousUrl();

    if (urlSegments && urlSegments.length > 0) {
      urlSegments.forEach(segment => {
        this.previousURL = `${this.previousURL}/${segment.path}`;
      });

      this.previousURLLoaded = true;
    } else if (notFoundLink.length > 0) {
      this.previousURL = notFoundLink;
      this.previousURLLoaded = true;
    }
  }
}
