import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  lastUpdatedDate = 'August 2, 2024';

  constructor() { }

  ngOnInit(): void {
  }

  open(url: string) {
    window.open(url, '_blank');
  }

}
