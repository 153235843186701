import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

// Models
import { EntitySearchQuery } from '../../queries/entity-search-query';

// Data Services
import { DistrictProfileService } from '../../../shared/services/data/district-profile.service';
import { Router } from '@angular/router';
import { EntitySearchService } from '../../../shared/services/data/entity-search.service';
import { EntitySearchResult } from '../../query-models/entity-search-model';

import { throwError } from 'rxjs';
import { ProfileService } from '../../services/helpers/profile.service';
import { DOCUMENT } from '@angular/common';
import { EntityType, SurveyYearKey } from '../../models/profile-model';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'app-navbar-search',
    templateUrl: './navbar-search.component.html',
    styleUrls: ['./navbar-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarSearchComponent implements OnInit, AfterViewInit {

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    @Output() isSearched: EventEmitter<boolean> = new EventEmitter<boolean>();

    surveyYearKey: SurveyYearKey;
    currentSearch: string = '';
    entities: EntitySearchResult[] = [];
    selectedEntity: number;
    pageKey: string;
    pageTitle: string;
    routeLoaded: boolean = false;
    runSearch: boolean = true;

    constructor(
        private router: Router,
        private readonly districtProfileService: DistrictProfileService,
        private readonly EntitySearchService: EntitySearchService,
        private readonly profileService: ProfileService,
        @Inject(DOCUMENT) private document: Document
    ) {

    }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        try {
            let input = this.ngSelectComponent.element.children[0].children[0].children[1].children[0];
            input['title'] = "Search by school, district, or state";
            input['autocomplete'] = "on";
        } catch (e) {
            //Silently fail just in case children break
        }

    }

    onChange(input: any) {
        if (input.key === 'Backspace') {
            this.currentSearch = input.target.value;
        } else if (input.key.length === 1) {
            this.currentSearch = input.target.value;
        } else if (input.key == 'Meta') {
            this.currentSearch = input.target.value
        }

        if (this.currentSearch.length >= 3 && this.runSearch) {
            this.runSearch = false;
            this.getEntitySearchData();
        } else if (this.currentSearch.length < 3) {
            this.runSearch = true;
            this.entities = [];
        }
    }

    OnSearch() {
        if (this.selectedEntity) {
            let entity = this.entities.find(entity => entity.entity_ID === this.selectedEntity);
            let entityType = entity.entity_Type;
            let surveyYears = JSON.parse(entity.syk).SYK;
            let yearKeys: number[] = surveyYears.map(year => year.Survey_Year_Key);
            let latestYearKey: number = Math.max(...yearKeys);

            this.surveyYearKey = this.profileService.getSurveyYearKeys(EntityType.GENERAL).find(year => year.yearKey === latestYearKey);
            sessionStorage.setItem("surveyYearKeys", entity.syk);

            switch (entityType) {
                case 'Schools':
                    this.routeSchoolProfile(entity);
                    break;
                case 'Districts':
                    this.routeDistrictProfile(entity);
                    break;
                case 'States':
                    this.routeStateEstimation();
                    break;
                default:
                    throwError("Unrecognized entity type.");
            }

            this.entities = [];
            this.selectedEntity = undefined;
            this.currentSearch = '';
            this.ngSelectComponent.handleClearClick();
            this.ngSelectComponent.blur();
        }
    }

    routeSchoolProfile(entity: any) {
        let state: string = entity.state_Name.replace(/\s+/g, '_').toLowerCase();
        let district: string = this.profileService.encodeCrdUri(entity.leA_Name.replace(/\s+/g, '_').toLowerCase());
        let school: string = this.profileService.encodeCrdUri(entity.school_Name.replace(/\s+/g, '_').toLowerCase());
        let ncesId: string = entity.lonG_NCES_ID;

        this.router.navigateByUrl(`profile/us/${state}/${district}/${school}?surveyYear=${this.surveyYearKey.surveyYear}&nces=${ncesId}`);
        this.isSearched.emit(true);
    }

    routeDistrictProfile(entity: any) {
        let state: string = entity.state_Name.replace(/\s+/g, '_').toLowerCase();
        let district: string = this.profileService.encodeCrdUri(entity.leA_Name.replace(/\s+/g, '_').toLowerCase());
        let ncesId: string = entity.lonG_NCES_ID;

        this.router.navigateByUrl(`profile/us/${state}/${district}?surveyYear=${this.surveyYearKey.surveyYear}&nces=${ncesId}`);
        this.isSearched.emit(true);
    }

    routeStateEstimation() {
        let stateEntity: EntitySearchResult = this.entities.find(entity => entity.entity_ID === this.selectedEntity);
        let stateName: string = stateEntity.entity_Name;
        let stateCode: any = this.profileService.getStateIds().find(state => state.stateName === stateName).state_Code;

        this.router.navigate([`profile/us/${stateCode}`], { queryParams: { surveyYear: this.surveyYearKey.surveyYear } });
        this.isSearched.emit(true);
    }

    getEntitySearchData() {
        let query: EntitySearchQuery = new EntitySearchQuery();
        query.entityName = this.currentSearch;

        this.EntitySearchService.getEntitySearchResult(query)
            .subscribe(data => {
                if (data) {
                    data.entitySearchResults.map(result => {
                        let entityType: string = result.entity_Type;

                        switch (entityType) {
                            case 'a':
                                result.entity_Type = 'Schools';
                                break;
                            case 'b':
                                result.entity_Type = 'Districts';
                                break;
                            case 'c':
                                result.entity_Type = 'States';
                                break;
                            default:
                                throwError(() => new Error("Search result with unknown entity type."));
                        }
                    });

                    let states = data.entitySearchResults.filter(entity => entity.entity_Type === 'States');
                    let districts = data.entitySearchResults.filter(entity => entity.entity_Type === 'Districts');
                    let schools = data.entitySearchResults.filter(entity => entity.entity_Type === 'Schools');

                    this.entities = [...states, ...districts, ...schools];
                }
            });
    }
}
