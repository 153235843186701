import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

// Services
import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';
import { MessageService } from '../core/message.service';

// Models
import { MiscQueryModel } from '../../query-models/misc-query-model';
import { MiscFieldQueryModel } from '../../query-models/misc-field-query-model';
import { EntityDataQuery } from '../../queries/entity-data-query';
import { MiscFieldDataQuery } from '../../queries/misc-field-data-query';




@Injectable()
export class MiscService extends BaseService {

    private apiUrl = environment.api_url; // URL to web api

    constructor(
        private http: HttpClient,
        messageService: MessageService,
        dataService: DataService
    ) {
        super(messageService, dataService);
    }

    /** GET Misc Field value**/
    getMiscField(query: MiscFieldDataQuery): Observable<MiscFieldQueryModel> {
        this.setHeaders();
        const url = this.apiUrl + 'MiscField';

        var params = new HttpParams();
        params = params.append('surveyYearKey', query.surveyYearKey.toString());
        params = params.append('isSchoolOrDistrict', query.isSchoolOrDistrict);
        params = params.append('entity_Id', query.entityId.toString());
        params = params.append('FieldList', query.field);

        return this.http.get<MiscFieldQueryModel>(
            url,
            {
                params: params,
                observe: 'response'
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }
}
