export interface ProfileBlockDto {
    entityId: number;
    entityType: EntityType;
    surveyYear: number;
    blockType: ProfileBlockTypes;
    section: any;
}

export interface SurveyYearKey {
    surveyYear: string;
    yearKey: number;
    period: string;
    surveyDescription: string;
}

export interface State {
    stateName: string;
    state_Id: number;
    state_Code: string;
}

export interface Measure {
    measure: any;
    title: string;
    toolTipVerb: string;
    dMeasure_Format: string;
}

export enum ProfileBlockTypes {
    STUDENT_POPULATION = 'Student Population',
    COURSES_AND_PROGRAMS = 'Courses and Programs',
    TEACHERS_SUPPORTS = 'Access to Teachers and Support Staff',
    STUDENT_RETENTION = 'Student Retention',
    STUDENT_DISCIPLINE = 'Student Discipline',
    SCHOOL_CLIMATE = 'School Climate',
}

export enum Genders {
    MALE = 'Male',
    FEMALE = 'Female',
    ALL_STUDENTS = 'All Students'
}

export enum SecondaryMetrics {
    RACE = 'Race'
}

export enum EntityType {
    SCHOOL = 'School',
    DISTRICT = 'District',
    STATE = 'State',
    NATIONAL = 'National',
    GENERAL = 'general'
}
