<div *ngIf="profileLoaded" [ngSwitch]="entityType">
    <div *ngSwitchCase="'School'">
        <div lang="en-us"
            class="school-profile-report-hero-container is-flex is-flex-direction-column is-align-items-center">
            <app-navigation-tree [schName]="schoolName" [leaName]="districtName" [leaId]="leaId" [state]="stateName"
                [stateCode]="stateCode" [stateId]="stateId" [surveyYearKey]="surveyYear">
            </app-navigation-tree>
            <app-survey-year-dropdown class="top-gap" [surveyYear]="surveyYear" [entityType]="entityType"
                (surveyYearUpdate)="updateSurveyYear($event)"></app-survey-year-dropdown>
            <h1 class="profile-header has-text-white has-text-centered">{{ schoolName }}</h1>
            <div class="bottom-gap top-gap is-flex is-flex-direction-column is-align-items-center">
                <div class="school-district-location is-flex is-flex-direction-row" style="gap: 10px">
                    <p class="has-text-white has-text-weight-semibold baskerville">{{ districtName }}</p>
                    <p class="has-text-white baskerville">|</p>
                    <p class="has-text-white baskerville">{{ schoolCity }}, {{ stateName }}</p>
                </div>
                <div *ngIf="ncesId" class="is-flex is-flex-direction-row" style="gap: 1rem">
                    <p class="bold-body has-text-white baskerville">NCES ID:</p>
                    <p class="body has-text-white baskerville">{{ ncesId }}</p>
                </div>
                <div *ngIf="!ncesId && combokey" class="is-flex is-flex-direction-row" style="gap: 1rem">
                    <p class="bold-body has-text-white baskerville">COMBOKEY:</p>
                    <p class="body has-text-white baskerville">{{ combokey }}</p>
                </div>
                <div class="info is-flex is-flex-direction-row">
                    <p class="bold-body has-text-white baskerville">Grades Offered:</p>
                    <p class="body has-text-white baskerville">{{ gradesOffered }}</p>
                </div>
                <div class="info is-flex is-flex-direction-row">
                    <p class="bold-body has-text-white baskerville">Type of School:</p>
                    <p class="body has-text-white baskerville">{{ typeOfSchool }}</p>
                </div>
                <div class="info is-flex is-flex-direction-row">
                    <p class="bold-body has-text-white baskerville">Students Enrolled:</p>
                    <p class="body has-text-white baskerville">{{ studentsEnrolled }}</p>
                </div>
                <div class="buttons is-flex is-flex-direction-row">
                    <!-- <button class="button is-rounded primary-button is-large-button">Add to Compare</button> -->
                    <button class="button is-rounded is-outlined secondary-outline-button is-large-button"
                        (click)="onViewSchools()">Find Schools Nearby</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'District'">
        <div lang="en-us"
            class="school-profile-report-hero-container is-flex is-flex-direction-column is-align-items-center">
            <app-navigation-tree [leaName]="districtName" [leaId]="leaId" [state]="stateName" [stateCode]="stateCode"
                [stateId]="stateId" [surveyYearKey]="surveyYear">
            </app-navigation-tree>
            <app-survey-year-dropdown class="top-gap" [surveyYear]="surveyYear" [entityType]="entityType"
                (surveyYearUpdate)="updateSurveyYear($event)"></app-survey-year-dropdown>
            <h1 class="profile-header has-text-white has-text-centered">{{ districtName }}</h1>
            <div class="bottom-gap top-gap is-flex is-flex-direction-column is-align-items-center">
                <div class="school-district-location is-flex is-flex-direction-row" style="gap: 10px">
                    <p class="has-text-white has-text-weight-semibold baskerville">{{ districtName }}</p>
                    <p class="has-text-white" baskerville>|</p>
                    <p class="has-text-white" baskerville>{{ districtCity }}, {{ stateName }}</p>
                </div>
                <div class="info is-flex is-flex-direction-row">
                    <p class="bold-body has-text-white baskerville">Grades Offered:</p>
                    <p class="body has-text-white baskerville">{{ gradesOffered }}</p>
                </div>
                <div class="info is-flex is-flex-direction-row">
                    <p class="bold-body has-text-white baskerville">Type of School:</p>
                    <p class="body has-text-white baskerville">{{ typeOfSchool }}</p>
                </div>
                <div class="info is-flex is-flex-direction-row">
                    <p class="bold-body has-text-white baskerville">Students Enrolled:</p>
                    <p class="body has-text-white baskerville">{{ studentsEnrolled }}</p>
                </div>
                <div class="buttons is-flex is-flex-direction-row">
                    <!-- <button class="button is-rounded primary-button is-large-button">Add to Compare</button> -->
                    <button class="button is-rounded is-outlined secondary-outline-button is-large-button"
                        (click)="onViewSchools()">Find Schools Nearby</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'State'" class="state-national-profile-report-hero-container">
        <div class="state-national-profile-report-hero-content is-flex is-flex-direction-column is-align-items-center">
            <div class="state-header is-flex is-flex-direction-row">
                <app-navigation-tree [state]="stateName" [stateCode]="stateCode" [surveyYearKey]="surveyYear">
                </app-navigation-tree>
                <app-survey-year-dropdown [surveyYear]="surveyYear" [entityType]="entityType"
                    (surveyYearUpdate)="updateSurveyYear($event)">
                </app-survey-year-dropdown>
            </div>
            <div class="state-container is-flex is-flex-direction-row">
                <div lang="en-us" class="state-container-content is-flex is-flex-direction-column">
                    <p class="large-body has-text-white">State Summary</p>
                    <h1 class="profile-header top-gap has-text-white">{{ stateName }}</h1>
                    <div class="state-subsection top-gap bottom-gap is-flex is-flex-direction-column">
                        <hr class="content-divider">
                        <div class="info is-flex is-flex-direction-row">
                            <p class="bold-body has-text-white baskerville">Number of Districts:</p>
                            <p class="body has-text-white baskerville">{{ totalDistricts }}</p>
                        </div>
                        <div class="info is-flex is-flex-direction-row">
                            <p class="bold-body has-text-white baskerville">Number of Schools:</p>
                            <p class="body has-text-white baskerville">{{ totalSchools }}</p>
                        </div>
                        <div class="info is-flex is-flex-direction-row">
                            <p class="bold-body has-text-white baskerville">Students Enrolled:</p>
                            <p class="body has-text-white baskerville">{{ studentsEnrolled }}</p>
                        </div>
                        <div class="buttons is-flex is-flex-direction-row">
                            <!-- <button class="button is-rounded primary-button is-large-button">Add to Compare</button> -->
                            <button class="button is-rounded is-outlined secondary-outline-button is-large-button"
                                (click)="onViewSchools()">Find Districts in {{ stateCode.toUpperCase() }}</button>
                        </div>
                    </div>
                </div>
                <img [src]="statePicture" [alt]="'Map of ' + stateName" class="state-picture" width="635px"
                    height="534px">
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'National'">
        <div
            class="state-national-profile-report-hero-container is-flex is-flex-direction-column is-align-items-center">
            <div class="state-header is-flex is-flex-direction-row">
                <app-navigation-tree [state]="stateName" [stateCode]="stateCode" [surveyYearKey]="surveyYear">
                </app-navigation-tree>
                <app-survey-year-dropdown [surveyYear]="surveyYear" [entityType]="entityType"
                    (surveyYearUpdate)="updateSurveyYear($event)">
                </app-survey-year-dropdown>
            </div>
            <div class="state-container is-flex is-flex-direction-row">
                <div lang="en-us" class="state-container-content is-flex is-flex-direction-column">
                    <p class="large-body has-text-white">Summary</p>
                    <h1 class="profile-header state-container-content-h1 top-gap has-text-white">National Data</h1>
                    <div class="state-subsection top-gap bottom-gap is-flex is-flex-direction-column">
                        <hr class="content-divider">
                        <div class="info is-flex is-flex-direction-row">
                            <p class="bold-body has-text-white baskerville">Number of Districts:</p>
                            <p class="body has-text-white baskerville">{{ totalDistricts }}</p>
                        </div>
                        <div class="info is-flex is-flex-direction-row">
                            <p class="bold-body has-text-white baskerville">Number of Schools:</p>
                            <p class="body has-text-white baskerville">{{ totalSchools }}</p>
                        </div>
                        <div class="info is-flex is-flex-direction-row">
                            <p class="bold-body has-text-white baskerville">Students Enrolled:</p>
                            <p class="body has-text-white baskerville">{{ studentsEnrolled }}</p>
                        </div>
                        <div class="buttons is-flex is-flex-direction-row">
                            <!-- <button class="button is-rounded primary-button is-large-button">Add to Compare</button> -->
                        </div>
                    </div>
                </div>
                <img [src]="statePicture" alt="Map of United States and Puerto Rico" class="state-picture">
            </div>
        </div>
    </div>
</div>
