import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
export interface DownloadYear {
  collectedYear: string;
  downloadableFiles: DownloadableFile[];
}

export interface DownloadableFile {
  name: string;
  href: string;
}

@Component({
  selector: 'app-recent-data-files',
  templateUrl: './recent-data-files.component.html',
  styleUrls: ['./recent-data-files.component.scss']
})
export class RecentDataFilesComponent implements OnInit {

    public Production: boolean = environment.production;

  public displayColumns: string[] = ['Collected Year', 'State and National Data', 'Downloadable Data Files',
    'CRDC School Survey Form', 'CRDC District Survey Form', 'Data File User\'s Manual', 'Data Notes',
    'Data Definitions', 'Data Notes Appendix'];

  public tableData: DownloadYear[] = [
    {
      collectedYear: '2023-24',
      downloadableFiles: [
        null,
        null,
        { name: '2020-21 CRDC School Survey Form', href: 'assets/downloads/2023-24-crdc-school-form-final.pdf' },
        { name: '2020-21 CRDC District Survey Form', href: 'assets/downloads/2023-24-crdc-lea-form-final.pdf' },
        null,
        null,
        null,
        null
      ]
    },
    {
      collectedYear: '2021-22',
      downloadableFiles: [
        null,
        null,
        { name: '2020-21 CRDC School Survey Form', href: 'assets/downloads/2021-22-crdc-school-form.pdf' },
        { name: '2020-21 CRDC District Survey Form', href: 'assets/downloads/2021-22-crdc-lea-form.pdf' },
        null,
        null,
        { name: '2021-22 Data Definitions', href: 'assets/downloads/2021-2220List20Definitions.pdf' },
        null
      ]
    },
    {
      collectedYear: '2020-21',
      downloadableFiles: [
        this.Production ? null : { name: '2020-21 National Estimations', href: '/estimations/2020-2021' },
        { name: '2020-21 Downloadable Data Files', href: 'assets/ocr/docs/2020-21-crdc-data.zip' },
        { name: '2020-21 CRDC School Survey Form', href: 'assets/downloads/2020-21-crdc-school-form.pdf' },
        { name: '2020-21 CRDC District Survey Form', href: 'assets/downloads/2020-21-crdc-lea-form.pdf' },
        { name: '2020-21 Data File User\'s Manual', href: "assets/downloads/2020-21 User's Manual.pdf" },
        null,
        { name: '2020-21 Data Definitions', href: "assets/downloads/Master List of Definitions.pdf" },
        { name: '2020-21 Data Notes Appendix', href: 'assets/downloads/2020-21 Appendix Workbook.xlsx' }
      ]
    },
    {
      collectedYear: '2017-18',
      downloadableFiles: [
        { name: '2017-18 National Estimations', href: '/estimations/2017-2018' },
        { name: '2017-18 Downloadable Data Files', href: 'assets/ocr/docs/2017-18-crdc-data.zip' },
        { name: '2017-18 CRDC School Survey Form', href: 'assets/for-researchers/school-form/2017-18-All-Schools-Form.pdf' },
        { name: '2017-18 CRDC District Survey Form', href: 'assets/for-researchers/district-form/2017-18-CRDC-LEA-Form.pdf' },
        { name: '2017-18 Data File User\'s Manual', href: 'assets/for-researchers/user-manual/2017-18 CRDC Public-Use Data File Manual.pdf' },
        { name: '2017-18 Data Notes', href: 'assets/for-researchers/data-notes/Data-Notes-CRDC-2017-18.pdf' },
        { name: '2017-18 Data Definitions', href: 'assets/downloads/2017-18_Master_List_of_CRDC_Definitions.pdf' },
        { name: '2017-18 Data Notes Appendix', href: 'assets/for-researchers/appendix/Appendix-C-LEAs-Identified-with-Post-Submission-Data-Quality-Checks.xlsx' }
      ]
    },
    {
      collectedYear: '2015-16',
      downloadableFiles: [
        { name: '2015-16 National Estimations', href: '/estimations/2015-2016' },
        { name: '2015-16 Downloadable Data Files', href: 'assets/ocr/docs/2015-16-crdc-data.zip' },
        { name: '2015-16 CRDC School Survey Form', href: 'assets/for-researchers/school-form/All-Schools-Form-CRDC-2015-16.pdf' },
        { name: '2015-16 CRDC District Survey Form', href: 'assets/for-researchers/district-form/Lea-Form-CRDC-2015-16.pdf' },
        { name: '2015-16 Data File User\'s Manual', href: 'assets/for-researchers/user-manual/Public-Use-Data-File-Manual-2015-16.pdf' },
        { name: '2015-16 Data Notes', href: 'assets/for-researchers/data-notes/Data-Notes-CRDC-2015-16.pdf' },
        { name: '2015-16 Data Definitions', href: 'assets/downloads/CRDC-Definitions-2015-16.pdf' },
        { name: '2015-16 Data Notes Appendix', href: 'assets/for-researchers/appendix/2015-16-data-notes-addendum.pdf' }
      ]
    },
    {
      collectedYear: '2013-14',
      downloadableFiles: [
        { name: '2013-14 National Estimations', href: '/estimations/2013-2014' },
        { name: '2013-14 Downloadable Data Files', href: 'assets/ocr/docs/2013-14-crdc-data.zip' },
        { name: '2013-14 CRDC School Survey Form', href: 'assets/for-researchers/school-form/All-Schools-Form-CRDC-2013-14.docx' },
        { name: '2013-14 CRDC District Survey Form', href: 'assets/for-researchers/district-form/Lea-Form-CRDC-2013-14.docx' },
        { name: '2013-14 Data File User\'s Manual', href: 'assets/for-researchers/user-manual/CRDC-Public-Use-Data-File-Users-Manual-2013-14.pdf' },
        { name: '2013-14 Data Notes', href: 'assets/for-researchers/data-notes/Data-Notes-CRDC-2013-14.pdf' },
        { name: '2013-14 Data Definitions', href: 'assets/downloads/CRDC-Defintions-2013-14.pdf' },
        null
      ]
    },
    {
      collectedYear: '2011-12',
      downloadableFiles: [
        { name: '2011-12 National Estimations', href: '/estimations/2011-2012' },
        { name: '2011-12 Downloadable Data Files', href: 'assets/ocr/docs/2011-12-crdc-data.zip' },
        { name: '2011-12 CRDC School Survey Form', href: 'assets/downloads/Table-Layouts-2011-12.docx' },
        { name: '2011-12 CRDC District Survey Form', href: 'assets/downloads/Table-Layouts-2011-12.docx' },
        null,
        { name: '2011-12 Data Notes', href: 'assets/for-researchers/data-notes/Data-Notes-CRDC-2011-12.pdf' },
        { name: '2011-12 Data Definitions', href: 'assets/downloads/CRDC-Definitions-2011-12.docx' },
        null
      ]
    },
    {
      collectedYear: '2009-10',
      downloadableFiles: [
        { name: '2009-10 National Estimations', href: '/estimations/2009-2010' },
        { name: '2009-10 Downloadable Data Files', href: 'assets/ocr/docs/2009-10-crdc-data.zip' },
        { name: '2009-10 CRDC School Survey Form', href: 'assets/downloads/p1-p2-3-CRDC-2009-10.docx' },
        { name: '2009-10 CRDC District Survey Form', href: 'assets/downloads/p1-p2-3-CRDC-2009-10.docx' },
        null,
        { name: '2009-10 Data Notes', href: 'assets/for-researchers/data-notes/Data-Notes-CRDC-2009-10.docx' },
        { name: '2009-10 Data Definitions', href: 'assets/downloads/CRDC-Definitions-2009-10.docx' },
        null
      ]
    },
    {
      collectedYear: '2006',
      downloadableFiles: [
        { name: '2006 National Estimations', href: '/estimations/2006' },
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ]
    },
    {
      collectedYear: '2004',
      downloadableFiles: [
        { name: '2004 National Estimations', href: '/estimations/2004' },
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ]
    },
    {
      collectedYear: '2000',
      downloadableFiles: [
        { name: '2000 National Estimations', href: '/estimations/2000' },
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ]
    },
  ]

  ngOnInit(): void { }

}
