import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { MessageService } from '../core/message.service';

import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';

import { EntitySearchQuery } from '../../queries/entity-search-query';
import { EntitySearchModel } from '../../query-models/entity-search-model';

@Injectable()
export class EntitySearchService extends BaseService {

  private apiUrl = environment.api_url; // URL to web api

  constructor(
    private http: HttpClient,
    messageService: MessageService,
    dataService: DataService
  ) {
    super(messageService, dataService);
  }

  /** GET **/
    getEntitySearchResult(query: EntitySearchQuery) {
    this.setHeaders();
      const url = this.apiUrl + 'EntitySearch';

      var params = new HttpParams();
      params = params.append('EntityName', query.entityName);

      return this.http.get<EntitySearchModel>(
      url,
      {
        params: params,
        observe: 'response'
      })
      .pipe(
        map(resp => {
          if (resp != undefined || resp != null) {
            return resp.body;
          }

          return null;
        }),
        tap(resp => {
          this.log(`fetched`);
        }),
        catchError(this.handleError('get', null))
      );
  }
}
