import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {

  reportsAndFindings: any[] = [
    {
      title: '2017-18 Sexual Violence',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/sexual-violence.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2017-18 Exclusionary Discipline',
      file: 'assets/downloads/crdc-exclusionary-school-discipline.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2017-18 Restraint and Seclusion',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/restraint-and-seclusion.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2015-16 STEM Course-Taking',
      file: 'assets/downloads/stem-course-taking.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2015-16 School Climate and Safety',
      file: 'assets/downloads/school-climate-and-safety.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2013-14 First Look Report',
      file: 'assets/downloads/2013-14-first-look.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2011-12 CRDC Data Snapshot: Teacher Equity',
      file: 'assets/downloads/2011-12_CRDC-Teacher-Equity-Snapshot.pdf',
      hasSpanish: true,
      spanishTitle: 'Spanish 2011-12 CRDC Data Snapshot: Teacher Equity',
      spanishFile: 'assets/downloads/CRDC-Teacher-Equity-Snapshot-sp-page1.pdf'
    },
    {
      title: '2011-12 CRDC Data Snapshot: School Discipline',
      file: 'assets/downloads/2011-12_CRDC-School-Discipline-Snapshot.pdf',
      hasSpanish: true,
      spanishTitle: 'Spanish 2011-12 CRDC Data Snapshot: School Discipline',
      spanishFile: 'assets/downloads/CRDC-School-Discipline-Snapshot-sp-page1.pdf'
    },
    {
      title: '2011-12 CRDC Data Snapshot: Early Childhood Education',
      file: 'assets/downloads/2011-12_CRDC-Early-Childhood-Education-Snapshot.pdf',
      hasSpanish: true,
      spanishTitle: 'Spanish 2011-12 CRDC Data Snapshot: Early Childhood Education',
      spanishFile: 'assets/downloads/CRDC-Early-Childhood-Education-Snapshot-sp-page1.pdf'
    },
    {
      title: '2011-12 CRDC Data Snapshot: College and Career Readiness',
      file: 'assets/downloads/2011-12_CRDC-College-and-Career-Readiness-Snapshot.pdf',
      hasSpanish: true,
      spanishTitle: 'Spanish 2011-12 CRDC Data Snapshot: College and Career Readiness',
      spanishFile: 'assets/downloads/CRDC-College-and-Career-Readiness-Snapshot-sp-page1.pdf'
    },
    {
      title: '2009-10 Revealing New Truths about Our Nation\'s Schools',
      file: 'assets/downloads/CMOCRTheTransformedCRDCFINAL3-15-12Accessible-1.pdf',
      hasSpanish: false,
      spanishFile: ''
    }
  ];

  disciplinePractices = [
    {
      title: '2017-18 Part 1: Discipline Practices in Preschool',
      file: 'assets/downloads/crdc-DOE-Discipline-Practices-in-Preschool-part1.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2017-18 Part 2: Suspensions and Expulsions in Public Schools',
      file: 'assets/downloads/Suspensions_and_Expulsion_Part2.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2017-18 Part 3: Suspensions and Expulsions of Students with Disabilities in Public Schools',
      file: 'assets/downloads/Discipline_of_Students_with_Disabilities_Part3.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2017-18 Part 4: Corporal Punishment in Public Schools',
      file: 'assets/downloads/Corporal_Punishment_Part4.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: '2017-18 Part 5: Referrals to Law Enforcement and School-Related Arrests in Public Schools',
      file: 'assets/downloads/Referrals_and_Arrests_Part5.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
  ]

  // Links are in order of weeks that need to be uncommented
  reportsAndSnapshots = [
    {
      title: 'A First Look Report',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-educational-opportunities-report.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'An Overview of the 2020-21 Civil Rights Data Collection Snapshot',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-overview-informational-snapshot.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'Civil Rights Data Quality Snapshot',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-quality-informational-snapshot.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'Student Discipline and School Climate Report',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-discipline-school-climate-report.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'Referrals to Law Enforcement and School-related Arrests Snapshot',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-law-enforcement-school-arrests-snapshot.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'Sexual Violence and Sex-based Harassment or Bullying Snapshot',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-sexual-violence-snapshot.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'Student Enrollment and School Characteristics Snapshot',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-student-enrollment-snapshot.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'Profile of Students with Disabilities Snapshot',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-student-disabilities-snapshot.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'Profile of English Learners Snapshot',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-el-students-snapshot.pdf',
      hasSpanish: false,
      spanishFile: ''
    },
    {
      title: 'Student Access to and Enrollment in Mathematics, Science, and Computer Science Courses and Academic Programs Report',
      file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-student-access-enrollment.pdf ',
      hasSpanish: false,
      spanishFile: ''
    },
  ]

  constructor() { }

  ngOnInit(): void { }

}
