import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { MessageService } from '../core/message.service';

import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';

import { SchoolSearchQueryModel } from '../../query-models/school-search-query-model';

@Injectable({
    providedIn: 'root',
})
export class SchoolSearchService extends BaseService {

    private apiUrl = environment.api_url; // URL to web api

    constructor(
        private http: HttpClient,
        messageService: MessageService,
        dataService: DataService
    ) {
        super(messageService, dataService);
    }

    /** GET **/
    get(query): Observable<SchoolSearchQueryModel[]> {
        this.setHeaders();
        const url = this.apiUrl + 'SchoolSearch';
        return this.http.get<SchoolSearchQueryModel[]>(
            url,
            {
                params: query,
                observe: 'response'
            })
            .pipe(
                map(resp => {
                    resp.body.forEach(x => x.visible = true);
                    return resp.body;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }
}
