import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDto, GroupValueDto } from 'src/app/shared/models/charts-model';
import { ScreenType } from 'src/app/shared/models/shared-model';
import { EntityType, ProfileBlockDto, ProfileBlockTypes, SurveyYearKey } from 'src/app/shared/models/profile-model';
import { StateNationalEtimationQuery } from 'src/app/shared/queries/state-national-estimation-query';
import { MeasureService } from 'src/app/shared/services/data/measure.service';
import { StateNationalEstimationService } from 'src/app/shared/services/data/state-national-estimation.service';
import { ProfileService } from 'src/app/shared/services/helpers/profile.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
    public areaChartDataLoaded: boolean = false;
    public areaChartData: ChartDto;
    public screenType: ScreenType;
    public ScreenType = ScreenType;
    public charts: any[];
    public selectedChart: any;
    public blocksLoaded: boolean;
    public profileBlocks: ProfileBlockDto[];
    private sections: any;
    public Production: boolean = environment.production;

    constructor(
        private readonly router: Router,
        private readonly profileService: ProfileService,
    ) {
        this.charts = [
            {
                label: 'Grade 8',
                src: 'assets/home-charts/grade8.jpg',
                download: 'assets/home-charts/grade8.xlsx',
                alt: 'download this chart 2020-21 Algebra 1 Nationwide Enrollment for Grade 8'
            },
            {
                label: 'Grades 9-10',
                src: 'assets/home-charts/grade9-10.jpg',
                download: 'assets/home-charts/grade9-10.xlsx',
                alt: 'download this chart 2020-21 Algebra 1 Nationwide Enrollment for Grade 9-10'
            },
            {
                label: 'Grades 11-12',
                src: 'assets/home-charts/grade11-12.jpg',
                download: 'assets/home-charts/grade11-12.xlsx',
                alt: 'download this chart 2020-21 Algebra 1 Nationwide Enrollment for Grade 11-12'
            }
        ];
        this.selectedChart = this.charts[0];
        this.blocksLoaded = false;
        this.profileBlocks = [];

        if (window.innerWidth < 1024 && window.innerWidth >= 768) {
            this.screenType = ScreenType.TABLET;
        } else if (window.innerWidth < 768) {
            this.screenType = ScreenType.MOBILE;
        } else {
            this.screenType = ScreenType.DESKTOP;
        }
    }

    async ngOnInit(): Promise<void> {
        await this.profileService.loadSurveyYearKeys();

        const surveyYears: SurveyYearKey[] = this.profileService.getSurveyYearKeys(EntityType.NATIONAL);

        if (surveyYears.length === 0) {
            return
        }

        const currentSurveyYear: SurveyYearKey = surveyYears[0];

        await this.profileService.loadMeasures(EntityType.NATIONAL, currentSurveyYear.yearKey);

        this.sections = this.profileService.getMeasures()

        const studentDiscipline: ProfileBlockDto = this.generateProfileBlock(ProfileBlockTypes.STUDENT_DISCIPLINE, 4);
        const schoolClimate: ProfileBlockDto = this.generateProfileBlock(ProfileBlockTypes.SCHOOL_CLIMATE, 5);

        this.profileBlocks.push(studentDiscipline);
        this.profileBlocks.push(schoolClimate);
        this.blocksLoaded = true;
    }

    @HostListener('window:resize', ['$event'])
    async onResize(event) {
        if (window.innerWidth < 1024 && window.innerWidth >= 768) {
            this.screenType = ScreenType.TABLET;
        } else if (window.innerWidth < 768) {
            this.screenType = ScreenType.MOBILE;
        } else {
            this.screenType = ScreenType.DESKTOP;
        }
    }

    navigate(path: string) {
        this.router.navigate([path]);
    }

    generateProfileBlock(blockType: ProfileBlockTypes, i: number): ProfileBlockDto {
        return {
            blockType: blockType,
            section: this.sections[i],
            entityId: null,
            surveyYear: 10,
            entityType: EntityType.NATIONAL
        };
    }

    routeAndScroll() {
        this.router.navigate(['/about/faqs'], { fragment: "question4" });
    }

    downloadExcelFile(): void {
        window.open(this.selectedChart.download, '_blank');
    }

}
