<div class="container content">
    <page-title title="2020-21 State and National Tables"></page-title>

    <p>The 2020-21 tables are based on data collected from all of the nation’s school districts and schools—approximately 17,800 school districts and 96,600 schools.</p>

    <mat-accordion>

        <span class="has-text-weight-bold">Student Enrollment</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Tables for Enrollment
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Student Enrollment</p>
                <p>
                    This set of Excel files contains data for all states on overall enrollment, enrollment of students served under IDEA,
                    enrollment of students served under Section 504, and enrollment of students who are English language learners.
                    For each category, there are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-quarter has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 1, 'Enrollment-Overall.xlsx')">Enrollment</button>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 3, 'Enrollment-IDEA.xlsx')">IDEA Enrollment</button>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 4, 'Enrollment-Section-504-only.xlsx')">Section 504 Enrollment</button>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 5, 'Enrollment-English-Learner.xlsx')">EL Enrollment</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Preschool Enrollment
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Preschool Enrollment</p>
                <p>
                    This Excel file contains preschool enrollment data for all states. The file contains three spreadsheets: total children, male children, and female children.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 2, 'Preschool-Enrollment.xlsx')">Preschool Enrollment</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">School Programs</span>


        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    English Language Instruction Educational Programs
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">English Language Instruction Program Enrollment</p>
                <p>This Excel file contains data for English language learners enrolled in English language instruction educational programs for all states. The file contains three spreadsheets: total children, male children, and female children.</p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 33, 'English-Language-Instruction-Programs.xlsx')">English language instruction educational program enrollment</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Gifted and Talented
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Gifted and Talented Enrollment</p>
                <p>
                    This Excel file contains data on students enrolled in gifted and talented programs for all states. The file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 34, 'Gifted-Talented-Enrollment.xlsx')">Gifted and talented enrollment</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    International Baccalaureate (IB)
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    International Baccalaureate Enrollment
                </p>
                <p>
                    This Excel file contains data on student enrollment in International Baccalaureate (IB) programs for all states.
                    The file contains three spreadsheets: total students, male students, and female students.

                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 35, 'International-Baccalaureate-Enrollment.xlsx')">IB Enrollment</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            Course Enrollment
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Advanced Placement Courses
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Advanced Placement Total Enrollment
                </p>
                <p>
                    This Excel file contains student enrollment in Advanced Placement for all states.
                    There are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="has-text-centered">
                    <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 9, 'Advanced-Placement-Enrollment.xlsx')">Advanced placement</button>
                </div>

                <br /><br />
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Advanced Placement Enrollment by Subject
                </p>
                <p>
                    This set of Excel files contains student enrollment data for all states, presented by Advanced Placement (AP) subject.
                    For each AP subject, there are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 10, 'Advanced-Placement-Mathematics-Enrollment.xlsx')">AP mathematics</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 11, 'Advanced-Placement-Science-Enrollment.xlsx')">AP science</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 12, 'Advanced-Placement-Computer-Science-Enrollment.xlsx')">AP computer science</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <!--<button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 13, 'Advanced-Placement-Other-Subjects-Enrollment.xlsx')">AP other subjects</button>-->
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Mathematics and Science
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <!--<p class="has-text-centered is-size-6 has-text-weight-bold">
                Schools Offering Mathematics and Science Courses
            </p>
            <p>
                This set of Excel files contains data for schools offering courses in mathematics and science for all states. Each file contains one spreadsheet for total schools.
            </p>
            <div class="columns is-gapless is-multiline">
                <div class="column has-text-centered">
                    <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Middle-Schools-Offering-Mathematics-Classes.xlsx" rel="noopener noreferrer" target="_blank">Middle schools offering mathematics courses</a>
                </div>
                <div class="column has-text-centered">
                    <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\High-Schools-Offering-Mathematics-Classes.xlsx" rel="noopener noreferrer" target="_blank">High schools offering mathematics courses</a>
                </div>
                <div class="column has-text-centered">
                    <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\High-Schools-Offering-Science-Classes.xlsx" rel="noopener noreferrer" target="_blank">High schools offering science courses</a>
                </div>
            </div>

            <br /><br />-->

                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Mathematics Tables by Course
                </p>
                <p>
                    This set of Excel files contains student enrollment data for all states, presented by mathematics course.
                    For each mathematics course, except Algebra I Grade 7 and Geometry Grade 8, there are three spreadsheets:
                    total students, male students, and female students. For each Algebra I Grade 7 and Geometry Grade 8 course,
                    there is one spreadsheet for total students.
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-fourth has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 50, 'Enrollment-in-Algebra-I-Grade-8.xlsx')">Algebra I Grade 8</button>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 51, 'Enrollment-in-Algebra-I-Grades-9-10.xlsx')">Algebra I Grades 9-10</button>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 52, 'Enrollment-in-Algebra-I-Grades-11-12.xlsx')">Algebra I Grades 11-12</button>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-fourth has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 53, 'Enrollment-in-High-School-Geometry.xlsx')">Geometry in high school</button>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 54, 'Enrollment-in-Algebra-II.xlsx')">Algebra II</button>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 55, 'Enrollment-in-Advanced-Mathematics.xlsx')">Advanced mathematics</button>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless has-text-centered">
                    <div class="column">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 56, 'Enrollment-in-Calculus.xlsx')">Calculus</button>
                    </div>
                </div>

                <br /><br />
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Science Tables by Course
                </p>
                <p style="padding: 5px;">
                    This set of Excel files contains student enrollment data for all states, presented by science course.
                    For each science course, there are three spreadsheets: total students, male students, and female students
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 57, 'Enrollment-in-Biology.xlsx')">Biology</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 58, 'Enrollment-in-Chemistry.xlsx')">Chemistry</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 59, 'Enrollment-in-Physics.xlsx')">Physics</button>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 60, 'Enrollment-in-Computer-Science.xlsx')">Computer Science</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            College Preparatory Exams
        </span>
        <!--<mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
            <mat-panel-title>
                Advanced Placement Exams
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="accordion-panel-body">
            <p class="has-text-centered is-size-6 has-text-weight-bold">
                Advanced Placement Exam Taking
            </p>
            <p>
                This set of Excel files contains data on Advanced Placement exam taking for all states.
                Each file contains three spreadsheets: total students, male students, and female students.
            </p>
            <div class="columns is-gapless is-multiline has-text-centered">
                <div class="column is-half">
                    <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 96, 'Advanced-Placement-Participation-Did-Not-Take-Exam.xlsx')">Did not take AP Exam</button>
                </div>
                <div class="column is-half">
                    <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 56, 'Advanced-Placement-Participation-Took-Exam.xlsx')">Took AP Exam</button>
                </div>
            </div>
        </div>
    </mat-expansion-panel>-->

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    SAT/ACT
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    SAT/ACT Participation
                </p>
                <p>
                    This Excel file contains SAT/ACT student participation data for all states.
                    The file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 8, 'SAT-or-ACT-Participation.xlsx')">SAT/ACT participation</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            Student Retention
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Retention
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Retention by Grade
                </p>
                <p>
                    This set of Excel files contains student retention data for all states, presented by grade.
                    For each grade, there are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 14, 'Retention-in-Kindergarten.xlsx')">Kindergarten</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 15, 'Retention-in-Grade-1.xlsx')">Grade 1</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 16, 'Retention-in-Grade-2.xlsx')">Grade 2</button>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 17, 'Retention-in-Grade-3.xlsx')">Grade 3</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 18, 'Retention-in-Grade-4.xlsx')">Grade 4</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 19, 'Retention-in-Grade-5.xlsx')">Grade 5</button>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 20, 'Retention-in-Grade-6.xlsx')">Grade 6</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 21, 'Retention-in-Grade-7.xlsx')">Grade 7</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 22, 'Retention-in-Grade-8.xlsx')">Grade 8</button>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 23, 'Retention-in-Grade-9.xlsx')">Grade 9</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 24, 'Retention-in-Grade-10.xlsx')">Grade 10</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 25, 'Retention-in-Grade-11.xlsx')">Grade 11</button>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 26, 'Retention-in-Grade-12.xlsx')">Grade 12</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">School Staff</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Classroom Teachers
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Classroom Teachers</p>
                <p>
                    These Excel files contain data on classroom teachers, including full-time equivalency counts and certification.
                    Each file contains one spreadsheet for total teachers.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 105, 'Teacher-Certification.xlsx')">Teacher Certification</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    School Support Staff
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="mat-expansion-panel-content-padding accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    School Support Staff
                </p>
                <p>
                    This set of Excel files contains data on full-time equivalent support staff, and schools with support staff,
                    by type of staff and for all states. Each file contains one spreadsheet for elementary, middle, or high schools.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 110, 'Counselors-High-School.xlsx')">Counselors High School</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">Discipline</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Discipline
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Discipline by Discipline Type
                </p>
                <p>
                    This set of Excel files contains data for all states, presented by discipline type.
                    For each discipline type, there are nine spreadsheets: total students with and without disabilities;
                    male students with and without disabilities; female students with and without disabilities;
                    total students with disabilities; male students with disabilities; female students with disabilities;
                    total students without disabilities; male students without disabilities; and female students without diabilities.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 61, 'Corporal-Punishment-SWOD.xlsx')">Corporal punishment – students without disabilities</button>
                    </div>
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 62, 'Corporal-Punishment-SWD.xlsx')">Corporal punishment – students with disabilities</button>
                    </div>

                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 63, 'One-or-More-In-School-Suspensions-SWOD.xlsx')">One or more in-school suspensions – students without disabilities</button>
                    </div>
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 64, 'One-or-More-In-School-Suspensions-SWD.xlsx')">One or more in-school suspensions – students with disabilities</button>
                    </div>

                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 65, 'One-Out-of-School-Suspensions-SWOD.xlsx')">One out-of-school suspension – students without disabilities</button>
                    </div>
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 66, 'One-Out-of-School-Suspensions-SWD.xlsx')">One out-of-school suspension – students with disabilities</button>
                    </div>

                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 67, 'More-Than-One-OOS-Suspensions-SWOD.xlsx')">More than one out-of-school suspensions – students without disabilities</button>
                    </div>
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 68, 'More-Than-One-OOS-Suspensions-SWD.xlsx')">More than one out-of-school suspensions – students with disabilities</button>
                    </div>

                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 69, 'One-or-More-OOS-Suspensions-SWOD.xlsx')">One or more out-of-school suspensions – students without disabilities</button>
                    </div>
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 70, 'One-or-More-OOS-Suspensions-SWD.xlsx')">One or more out-of-school suspensions – students with disabilities</button>
                    </div>

                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 71, 'Expulsions-with-ed-service-SWOD.xlsx')">Expulsion with educational services – students without disabilities</button>
                    </div>
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 72, 'Expulsions-with-ed-service-SWD.xlsx')">Expulsion with educational services – students with disabilities</button>
                    </div>

                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 73, 'Expulsions-without-ed-service-SWOD.xlsx')">Expulsion without educational services – students without disabilities</button>
                    </div>
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 74, 'Expulsions-without-ed-service-SWD.xlsx')">Expulsion without educational services – students with disabilities</button>
                    </div>

                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 75, 'Expulsions-w-and-wo-ed-service-SWOD.xlsx')">Expulsions with and without educational services – students without disabilities</button>
                    </div>
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 76, 'Expulsions-w-and-wo-ed-service-SWD.xlsx')">Expulsions with and without educational services – students with disabilities</button>
                    </div>

                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Preschool Discipline
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Preschool Discipline by Discipline Type
                </p>
                <p>
                    This set of Excel files contains data for all states, presented by discipline type. For each discipline type,
                    there are three spreadsheets: total preschool students, male preschool students, and female preschool students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 44, 'Preschool-Corporal-Punishment.xlsx')">Preschool corporal punishment</button>
                    </div>
                    <!--<div class="column is-half has-text-centered">
                    <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 1, 'Preschool-One-Out-of-School-Suspension.xlsx')">Preschool one out-of-school suspension</button>
                </div>-->
                    <!--</div>
                <div class="columns is-gapless is-multiline">-->
                    <!--<div class="column is-half has-text-centered">
                    <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 1, 'Preschool-More-than-One-Out-of-School-Suspension.xlsx')">Preschool more than one out-of-school suspension</button>
                </div>-->
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 47, 'Preschool-One-or-More-Out-of-School-Suspensions.xlsx')">Preschool one or more out-of-school suspensions</button>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 48, 'Preschool-Expulsions.xlsx')">Preschool expulsions</button>
                    </div>
                </div>

                <!--<br /><br />

                  <p class="has-text-centered is-size-6 has-text-weight-bold">
                      Preschool Incidents of Corporal Punishment and Out-of-School Suspensions
                  </p>
                  <p>
                      This Excel file contains data on incidents of preschool corporal punishment and preschool out-of-school suspension for all states.
                  </p>
            <div class="columns is-gapless is-multiline has-text-centered">
                <div class="column">
                    <a href="assets\downloads\2017-2018\Discipline\Discipline\Preschool-Discipline.xlsx" rel="noopener noreferrer" target="_blank">Incidents of Preschool corporal punishment and suspension</a>
                </div>
            </div>-->
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            Restraint and Seclusion
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Restraint and Seclusion
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Restraint and Seclusion</p>
                <p>
                    This set of Excel files contains mechanical restraint, physical restraint, and seclusion data on students for all states.
                    Each file contains nine spreadsheets: total students served under IDEA and not served under IDEA; male students served under
                    IDEA and not served under IDEA; female students served under IDEA and not served under IDEA; total students served under IDEA;
                    male students served under IDEA; female students served under IDEA; total students not served under IDEA; male students
                    not served under IDEA; and female students not served under IDEA.
                </p>
                <!--<div class="columns is-gapless is-multiline">
                <div class="column is-one-third has-text-centered">
                    <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-under-IDEA-or-not_mech.xlsx" rel="noopener noreferrer" target="_blank">Mechanical restraint IDEA/Non IDEA</a>
                </div>
                <div class="column is-one-third has-text-centered">
                    <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-under-IDEA-or-not_phys.xlsx" rel="noopener noreferrer" target="_blank">Physical restraint IDEA/Non IDEA</a>
                </div>
                <div class="column is-one-third has-text-centered">
                    <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-under-IDEA-or-not_secl.xlsx" rel="noopener noreferrer" target="_blank">Seclusion IDEA/Non IDEA</a>
                </div>
            </div>-->
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 30, 'Mechanical-Restraint-IDEA-Students.xlsx')">Mechanical Restraint – IDEA Students</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 31, 'Physical-Restraint-IDEA-Students.xlsx')">Physical Restraint – IDEA Students</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 32, 'Seclusion-of-IDEA-Students.xlsx')">Seclusion – IDEA Students</button>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 27, 'Mechanical-Restraint-Non-IDEA-Students.xlsx')">Mechanical Restraint – Non-IDEA Students</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 28, 'Physical-Restraint-Non-IDEA-Students.xlsx')">Physical Restraint – Non-IDEA Students</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 29, 'Seclusion-of-Non-IDEA-Students.xlsx')">Seclusion – Non-IDEA Students</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            School Climate
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Harassment or Bullying
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Harassment or Bullying by Basis Category</p>
                <p>
                    This set of Excel files contains data on students reported as harassed or bullied or disciplined for
                    harassment or bullying on the basis of sex, race, or disability category for all states. Each file contains three spreadsheets:
                    total students, male students, and female students.
                </p>

                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 80, 'Harassment-Bullying-on-basis-of-sex-reported.xlsx')">On basis of sex - reported</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 81, 'Harassment-Bullying-on-basis-of-race-reported.xlsx')">On basis of race - reported</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 82, 'Harassment-Bullying-on-basis-of-disability-reported.xlsx')">On basis of disability - reported</button>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 83, 'Harassment-Bullying-on-basis-of-sex-disciplined.xlsx')">On basis of sex - disciplined</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 84, 'Harassment-Bullying-on-basis-of-race-disciplined.xlsx')">On basis of race - disciplined</button>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 85, 'Harassment-Bullying-on-basis-of-disability-discplined.xlsx')">On basis of disability - disciplined</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Arrests
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Referred to Law Enforcement
                </p>
                <p>
                    This Excel file contains data on student referrals to law enforcement by disability for all states.
                </p>

                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 90, 'Referred-to-Law-Enforcement-SWD.xlsx')">Referral – Students with Disabilities</button>
                    </div>
                    <!--<div class="column is-one-third has-text-centered">
                    <a href="assets\downloads\2017-2018\School-Climate\Arrests\Referred-to-Law-Enforcement\Referred-to-Law-Enforcement_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">
                        Referred to Law Enforcement with and without disability
                    </a>-->
                    <!--                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 91, 'Referred-to-Law-Enforcement_by-disability-and-no.xlsx')">Referred to Law Enforcement with and without disability</button>-->
                    <!--</div>-->
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 92, 'Referred-to-Law-Enforcement-SWOD.xlsx')">Referral – Students without Disabilities</button>
                    </div>
                </div>
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    School-Related Arrests
                </p>
                <p>
                    This Excel file contains data on student-related arrests by disability for all states.
                </p>

                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 93, 'School-Related-Arrest-SWD.xlsx')">Arrest – Students with Disabilities</button>
                    </div>
                    <!--<div class="column is-one-third has-text-centered">
                    <a href="assets\downloads\2017-2018\School-Climate\Arrests\School-Related-Arrest\School-Related-Arrest_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">
                        School-Related Arrests with and without disability
                    </a>-->
                    <!--<button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 94, 'School-Related-Arrest_by-disability-and-no.xlsx')">School-Related Arrests with and without disability</button>-->
                    <!--</div>-->
                    <div class="column is-one-third has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 95, 'School-Related-Arrest-SWOD.xlsx')">Arrest – Students without Disabilities</button>
                    </div>
                </div>


            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Offenses
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Incidents of Offenses
                </p>
                <p>
                    This Excel file contains data on incidents of offenses by type of offense for all states.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <button role="menuitem" mat-button (click)="downloadStateNationalEstimationExcelFile(10, 100, 'Offenses.xlsx')">Offenses</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>



    </mat-accordion>

    <document-viewer docType="PDF"></document-viewer>
</div>
