import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  
  @Input() isClicked: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showModal: boolean;

  constructor() {
    this.showModal = false;
  }

  ngOnInit() {
  }

  public open() {
    this.isClosed.emit(true);
    this.showModal = true;
  }

  public close() {
    this.showModal = false;
    this.isClosed.emit(true);
  }

}
