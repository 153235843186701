<div class="recent-data-files-container">
    <app-hero heroHeader="Recent Data Files" heroSubHeader="Downloads"
        heroDescription="Data collected through CRDC allows researchers, analysts, and journalists to identify new and important insights into the state of civil rights in education."
        heroImageSrc="assets/images/recent-data-files.jpg"
        heroImageAlt="a woman sitting at a desk looking at two computer monitors showing data">
    </app-hero>
    <div class="data-collection-container">
        <div class="data-collection is-flex is-flex-direction-column is-justify-content-center">
            <img src="assets/images/recent-data-files-1.png" class="rounded-corners"
                alt="a cork board covered in post-it notes" />
            <div class="grid-content-container is-flex is-flex-direction-column">
                <h4 class="has-text-primary">Data File Downloads</h4>
                <div class="data-file-downloads-table-container">
                    <hr class="top-line">
                    <table class="data-file-downloads-table">
                        <tr>
                            <th *ngFor="let column of displayColumns" class="large-body has-text-primary">
                                {{ column }}
                            </th>
                        </tr>

                        <tr *ngFor="let datum of tableData; let i = index" class="data-file-downloads-table-data">
                            <td class="large-body has-text-primary">{{ datum.collectedYear }}</td>
                            <td *ngFor="let file of datum.downloadableFiles">
                                <a *ngIf="file !== null"
                                   class="is-underlined large-body has-text-primary" [href]="file.href" target="_blank"
                                   rel="noreferrer noopener">Download</a>
                            </td>
                        </tr>
                    </table>

                    <hr class="bottom-line">
                </div>
            </div>
        </div>
    </div>
</div>
