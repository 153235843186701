import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComparisonService } from '../../services/helpers/comparison.service';
import { NavbarSearchComponent } from '../navbar-search/navbar-search.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

/**
 * This class allows us to update the navbar on certain events.
 */
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean;
  @Output() showCompareNav: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('about') about: ElementRef;
  @ViewChild('aboutSubMenu') aboutSubMenu: ElementRef;
  @ViewChild('exploreData') exploreData: ElementRef;
  @ViewChild('exploreDataSubMenu') exploreDataSubMenu: ElementRef;

  showSubMenu: boolean = false;
  elementExpanded: string;

  private navbarSearch: NavbarSearchComponent;

  @ViewChild('navbarSearch', { static: false }) set content(content: NavbarSearchComponent) {
    if (content) {
      this.navbarSearch = content;
    }
  }

  isMobileMenuVisible: boolean = false;

  numSchools: number;
  comparisonSubscription: Subscription;
  production: boolean = environment.production;

  constructor(
    private comparisonService: ComparisonService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.numSchools = comparisonService.getReportCount();
   }

  ngOnInit(): void {
    this.comparisonSubscription = this.comparisonService.comparisonUpdate.subscribe((event) => {
    })
  }

  ngOnDestroy(): void {
    this.comparisonSubscription.unsubscribe();
  }

  @HostListener('document:keydown.escape', ['$event']) 
  onKeydownHandler(event: KeyboardEvent) {
    if (this.isMobileMenuVisible && this.isMobile) {
      this.toggleMobileNavbar();
    }

    if (this.showSubMenu) {
      switch (this.elementExpanded) {
        case 'about':
          this.about.nativeElement.focus();
          break;
        case 'exploreData':
          this.exploreData.nativeElement.focus();
          break;
      }
    }
  }

  onKeyDown(event: KeyboardEvent, element: string) {
    switch (event.key) {
      case "ArrowUp":
        event.stopPropagation();
        document.activeElement.parentElement.previousElementSibling?.querySelector('a').focus();
        break;
    
      case "ArrowDown":
        event.stopPropagation();

        if (element === 'about') {
          this.aboutSubMenu.nativeElement.querySelector('a').focus();
        } else if (element === 'exploreData') {
          this.exploreDataSubMenu.nativeElement.querySelector('a').focus();
        } else if (element === 'aboutSubMenu' || element === 'exploreDataSubMenu') {
          document.activeElement.parentElement.nextElementSibling?.querySelector('a').focus();
        }
        break;
    }
  }

  /**
   * This method toggles the mobile navbar such that in mobile configurations it is visible,
   * and in desktop, the normal navbar is visible.
   */
  toggleMobileNavbar(close?: boolean) {
    if (this.isMobile) {
      if (close) {
        this.isMobileMenuVisible = false;
      } else {
        this.isMobileMenuVisible = !this.isMobileMenuVisible;
      }
    }

    this.cdRef.detectChanges();

    if (this.isMobileMenuVisible) {
      this.navbarSearch.ngSelectComponent.searchInput.nativeElement.focus();
    }
  }

  showSideNav() {
    this.showCompareNav.emit(false);
  }

  expand(elementId: string, expand: string) {
    document.getElementById(elementId).ariaExpanded = expand;
    this.elementExpanded = elementId;
    this.showSubMenu = Boolean(expand);
  }

}
