<div class="profile-page-container is-flex is-flex-direction-column">
    <app-profile-report-hero [surveyYear]="surveyYearKey" [entityType]="entityType" (surveyYearUpdate)="updateSurveyYear($event)" (viewSchools)="onViewSchools()"></app-profile-report-hero>

    <app-quick-stats [surveyYear]="surveyYearKey" [entityId]="entityId" [entityType]="entityType"></app-quick-stats>

    <div class="profile-block-color">
        <div *ngIf="blocksLoaded" class="profile-block-container">
            <div class="profile-block" *ngFor="let profileBlock of profileBlocks; let i = index">
                <app-profile-block [direction]="i % 2 === 0 ? 'right' : 'left'" [id]="profileBlock.blockType" [profileBlock]="profileBlock" class="profile-block">
                </app-profile-block>
            </div>
        </div>
    </div>

    <app-share-report></app-share-report>

    <app-find-nearby *ngIf="entityType != 'National'" id="find-nearby" [entityType]="entityType" [surveyYearKey]="surveyYearKey"></app-find-nearby>
</div>
