import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconComponent } from '../components/icon/icon.component';
import { NgSelectModule } from '@ng-select/ng-select';
// Core components
import { SizeDetectorComponent } from '../components/size-detector/side-detector.component';

// Core Services
import { ResizeService } from '../services/core/resize.service';

// Data Services
import { MiscService } from '../services/data/misc.service';

// UI
import { MaterialModule } from './material.module';
import { ChartsModule } from 'src/app/features/charts/charts.module';
import { CarouselComponentModule } from '../components/carousel/carousel.module';
import { HomeFeaturedReportsComponent } from '../components/home-featured-reports/home-featured-reports.component';
import { NavbarSearchComponent } from '../components/navbar-search/navbar-search.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { ProfileReportHeroComponent } from '../components/profile-report-hero/profile-report-hero.component';
import { QuickStatsComponent } from '../components/quick-stats/quick-stats.component';
import { FooterComponent } from '../components/footer/footer.component';
import { NavigationTreeComponent } from '../components/navigation-tree/navigation-tree.component';
import { ProfilePageComponent } from '../components/profile-page/profile-page.component';
import { SurveyYearDropdownComponent } from '../components/survey-year-dropdown/survey-year-dropdown.component';
import { ShareReportComponent } from '../components/share-report/share-report.component';
import { FindNearbyComponent } from '../components/find-nearby/find-nearby.component';
import { WildcardInterceptorComponent } from '../components/wildcard-interceptor/wildcard-interceptor.component';
import { TooltipDirective } from '../directives/tooltip.directive';
import { PageTitleComponent } from '../components/page-title/page-title.component';
import { DocumentViewerComponent } from '../components/document-viewer/document-viewer.component';
import { ProfileBlockComponent } from '../components/profile-block/profile-block.component';
import { LoadingSpinnerComponent } from '../components/loading-spinner/loading-spinner.component';
import { ChartDataCardComponent } from '../components/chart-data-card/chart-data-card.component';
import { ModalComponent } from '../components/modal/modal.component';
import { HeroComponent } from '../components/hero/hero.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
    declarations: [
        IconComponent,
        SizeDetectorComponent,
        HomeFeaturedReportsComponent,
        NavbarSearchComponent,
        NavbarComponent,
        ProfileReportHeroComponent,
        QuickStatsComponent,
        FooterComponent,
        NavigationTreeComponent,
        ProfilePageComponent,
        SurveyYearDropdownComponent,
        ShareReportComponent,
        FindNearbyComponent,
        WildcardInterceptorComponent,
        TooltipDirective,
        PageTitleComponent,
        DocumentViewerComponent,
        ProfileBlockComponent,
        LoadingSpinnerComponent,
        ChartDataCardComponent,
        ModalComponent,
        HeroComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ChartsModule,
        CarouselComponentModule,
        NgSelectModule,
        MatBottomSheetModule
    ],
    exports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ChartsModule,
        CarouselComponentModule,
        IconComponent,
        SizeDetectorComponent,
        NgSelectModule,
        HomeFeaturedReportsComponent,
        NavbarSearchComponent,
        NavbarComponent,
        ProfileBlockComponent,
        FooterComponent,
        ProfileReportHeroComponent,
        QuickStatsComponent,
        NavigationTreeComponent,
        ProfilePageComponent,
        SurveyYearDropdownComponent,
        WildcardInterceptorComponent,
        PageTitleComponent,
        DocumentViewerComponent,
        LoadingSpinnerComponent,
        ModalComponent,
        HeroComponent,
        TooltipDirective
    ],
    providers: [
        ResizeService,
        MiscService
    ]
})
export class SharedModule { }
