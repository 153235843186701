import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScreenType } from 'src/app/shared/models/shared-model';

@Component({
  selector: 'app-about-faq',
  templateUrl: './about-faq.component.html',
  styleUrls: ['./about-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutFaqComponent implements OnInit, OnDestroy, AfterViewInit {
  public question1FontWeight: number = 400;
  public question2FontWeight: number = 400;
  public question3FontWeight: number = 400;
  public question4FontWeight: number = 400;
  public question5FontWeight: number = 400;
  public question6FontWeight: number = 400;
  public question7FontWeight: number = 400;
  public question8FontWeight: number = 400;
  public question9FontWeight: number = 400;

  private scrollDispatcherSub: Subscription;

  ScreenType = ScreenType;
  public screenType: ScreenType = ScreenType.DESKTOP;
  public isClicked: boolean = false;

  private fragment: string;

  constructor(private scrollDispatcher: ScrollDispatcher, private cdRef: ChangeDetectorRef, private route: ActivatedRoute) {
    this.scrollDispatcherSub = this.scrollDispatcher.scrolled().subscribe((event: CdkScrollable) => {
      this.handleScrollChange(event.measureScrollOffset("top"));
    });
  }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });

    if (window.innerWidth < 1024 && window.innerWidth >= 851) {
      this.screenType = ScreenType.TABLET;
    } else if (window.innerWidth < 851) {
      this.screenType = ScreenType.MOBILE;
    } else {
      this.screenType = ScreenType.DESKTOP;
    }
  }

  ngOnDestroy(): void {
    this.scrollDispatcherSub.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  async onResize(event) {
    if (window.innerWidth < 1024 && window.innerWidth >= 851) {
      this.screenType = ScreenType.TABLET;
    } else if (window.innerWidth < 851) {
      this.screenType = ScreenType.MOBILE;
    } else {
      this.screenType = ScreenType.DESKTOP;
    }
  }

  ngAfterViewInit(): void {
    try {
      this.customScroll(document.querySelector('#' + this.fragment));
    } catch (e) {}
  }

  customScroll(el: HTMLElement) {
    el.offsetParent.scrollTop = el.offsetTop - 40;
    this.isClicked = true;
  }

  handleScrollChange(yOffset: number) {
    let normalFontWeight = 400;
    let heavyFontWeight = 800;

    let question1 = document.getElementById("question1");
    let question2 = document.getElementById("question2");
    let question3 = document.getElementById("question3");
    let question4 = document.getElementById("question4");
    let question5 = document.getElementById("question5");
    let question6 = document.getElementById("question6");
    let question7 = document.getElementById("question7");
    let question8 = document.getElementById("question8");
    let question9 = document.getElementById("question9");

    let verticalPadding = question1.offsetHeight * .2;

    let question1Pos = question1.offsetTop - verticalPadding;
    let question2Pos = question2.offsetTop - verticalPadding;
    let question3Pos = question3.offsetTop - verticalPadding;
    let question4Pos = question4.offsetTop - verticalPadding;
    let question5Pos = question5.offsetTop - verticalPadding;
    let question6Pos = question6.offsetTop - verticalPadding;
    let question7Pos = question7.offsetTop - verticalPadding;
    let question8Pos = question8.offsetTop - verticalPadding;
    let question9Pos = question9.offsetTop - verticalPadding;

    if (yOffset < question1Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question1Pos && yOffset < question2Pos) {
      this.question1FontWeight = heavyFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question2Pos && yOffset < question3Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = heavyFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question3Pos && yOffset < question4Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = heavyFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question4Pos && yOffset < question5Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = heavyFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question5Pos && yOffset < question6Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = heavyFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question6Pos  && yOffset < question7Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = heavyFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question7Pos  && yOffset < question8Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = heavyFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question8Pos  && yOffset < question9Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = heavyFontWeight;
      this.question9FontWeight = normalFontWeight;
    } else if (yOffset >= question9Pos) {
      this.question1FontWeight = normalFontWeight;
      this.question2FontWeight = normalFontWeight;
      this.question3FontWeight = normalFontWeight;
      this.question4FontWeight = normalFontWeight;
      this.question5FontWeight = normalFontWeight;
      this.question6FontWeight = normalFontWeight;
      this.question7FontWeight = normalFontWeight;
      this.question8FontWeight = normalFontWeight;
      this.question9FontWeight = heavyFontWeight;
    }

    this.cdRef.detectChanges();
  }

  clickQuestion() {
    this.isClicked = false;
  }

}
