<div class="search is-flex is-flex-direction-row is-justify-content-space-between">
    <ng-select id="myNgSelect" name="Navbar search" class="custom" [items]="entities" bindLabel="entity_Name" bindValue="entity_ID"
        groupBy="entity_Type" [clearable]="false" placeholder="Search by school, district, or state"
        [(ngModel)]="selectedEntity" (keyup)="onChange($event)" [isOpen]="entities.length > 0 && ngSelectComponent.focused" (ngModelChange)="OnSearch()">
        <ng-template ng-optgroup-tmp let-item="item">
            {{item.entity_Type || 'Unnamed group'}}
        </ng-template>
    </ng-select>
    <button class="search-button" title="Search by school, district, or state" mat-icon-button (click)="OnSearch()">
        <mat-icon class="search-button-icon" matSuffix>search</mat-icon>
    </button>
</div>
