<app-size-detector></app-size-detector>

<!-- <nav class="navbar top-nav is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <button class="side-nav-toggle is-hidden-desktop btn-menu" mat-icon-button (click)="this.toggleSideNav()">
            <icon [iconString]="'fas fa-bars'"></icon><span class="is-sr-only">Toggle Side Navigation</span>
        </button> 
        <h1 class="top-nav--title is-size-4" *ngIf="!isMobile">
            <span class="has-text-primary">CIVIL RIGHTS </span><span class="has-text-info">Data Collection</span>
        </h1>
        <h1 class="top-nav--title-mobile is-size-6" *ngIf="isMobile">
            <span class="has-text-primary">CIVIL RIGHTS </span><span class="has-text-info">Data Collection</span>
        </h1>
    </div>
</nav> -->


<a class="skip-to-content-link" [href]="skipLinkPath">Skip to content</a>
<app-navbar [isMobile]="isMobile" (showCompareNav)="toggleSideNav()"></app-navbar>
<mat-sidenav-container  hasBackdrop="false" class="site--container">
    <mat-sidenav [mode]="modeType" position="end" #sidenav [opened]="sideNavOpened" class="side-nav--container">
        <side-nav (keydown.escape)="close('escape')" (closePressed)="close($event)"></side-nav>
    </mat-sidenav>
    <mat-sidenav-content id="content" class="site-content--container">
        <section class="site-content">
            <router-outlet (activate)="scrollToTop()"></router-outlet>
            <app-footer></app-footer>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>
