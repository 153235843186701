import { NgModule } from '@angular/core';

// Form

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
//import { MatAutocompleteModule } from '@angular/material/autocomplete';
//import { MatSliderModule } from '@angular/material/slider';
//import { MatChipsModule } from '@angular/material/chips';

// Data

import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';

// Navigation

import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
//import { MatToolbarModule } from '@angular/material/toolbar';

// Layout
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
//import { MatListModule } from '@angular/material/list';

// Misc
//import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatBottomSheetRef, MatBottomSheetModule, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
//import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatMenuModule,
        MatSidenavModule,
        MatTabsModule,
        MatDividerModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatExpansionModule,
        MatRadioModule,
        MatTreeModule,
        //MatChipsModule,
        //MatAutocompleteModule,
        //MatSliderModule,
        //MatToolbarModule,
        //MatCardModule,
        //MatListModule,
        MatIconModule,
        //DragDropModule,
        //MatTooltipModule,
        MatBottomSheetModule
    ],
    exports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatMenuModule,
        MatSidenavModule,
        MatTabsModule,
        MatDividerModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatSelectModule,
        MatRadioModule,
        MatTreeModule,
        //MatChipsModule,
        //MatAutocompleteModule,
        //MatSliderModule,
        //MatToolbarModule,
        MatCardModule,
        //MatListModule,
        MatIconModule,
        //DragDropModule,
        //MatTooltipModule
        MatBottomSheetModule
    ],
    providers: [
        //MatIconRegistry
        { provide: MAT_SNACK_BAR_DATA, useValue: {} }, MatSnackBar,
        { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
        { provide: MatBottomSheetRef, useValue: {} },
        { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
    ]
})
export class MaterialModule {
    constructor(
        public matIconRegistry: MatIconRegistry) {
        matIconRegistry.registerFontClassAlias('fontawesome', '');
    }
}
