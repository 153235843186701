<button mat-mini-fab color="#133559" aria-label="Open table of contents modal" class="toggle-button" (click)="open()">
    <mat-icon>menu</mat-icon>
</button>

<div *ngIf="showModal && !isClicked">
    <div class="modal-container" (click)="close()">
        <div class="modal-container-content" (click)="$event.stopPropagation()">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="modal-background"></div>
</div>
