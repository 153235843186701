import { Injectable } from '@angular/core';
import { EntityType, ProfileBlockTypes, State, SurveyYearKey } from '../../models/profile-model';
import { SurveyYearService } from '../data/survey-year.service';
import { StatesService } from '../data/states.service';
import { Router } from '@angular/router';
import { EntityMeasuresQuery } from '../../queries/entity-measures-query';
import { MeasureService } from '../data/measure.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private entityId: number;
    private notFoundLink: string = '';
    private surveyYearKeys: SurveyYearKey[];
    private sections: any;
    private states: State[];

    constructor(
        private readonly surveyYearService: SurveyYearService,
        private readonly statesService: StatesService,
        private readonly measureService: MeasureService,
        private router: Router) {
    }

    setEntityId(id: number) {
        this.entityId = id;
    }

    getEnttityId() {
        return this.entityId;
    }

    getNotFoundLink(): string {
        return this.notFoundLink;
    }

    setNotFoundLink(route: string) {
        this.notFoundLink = route;
    }

    getSurveyYearKeys(entityType: EntityType) {
        if (entityType === EntityType.SCHOOL || entityType === EntityType.DISTRICT) {
            let syk: any = JSON.parse(sessionStorage.getItem('surveyYearKeys'));
            let filteredSyk: any = syk.SYK === undefined ? [] : syk.SYK.filter(year => year.Survey_Year_Key > 5);

            let surveyYears: SurveyYearKey[] = filteredSyk.map(year => {
                let currentYear: string = year.SURVEY_YEAR;
                return this.surveyYearKeys.find(surveyYear => currentYear === surveyYear.surveyYear);
            }).reverse();

            return surveyYears
        } else {
            return this.surveyYearKeys ? this.surveyYearKeys.filter(syk => syk.yearKey > 5) : [];
        }
    }

    setSurveyYearKeys(surveyYearKeys: SurveyYearKey[]) {
        this.surveyYearKeys = surveyYearKeys;
    }

    loadSurveyYearKeys(): Promise<void> {
        return new Promise(resolve => {
            this.surveyYearService.getSurveyYearsList(10).subscribe(data => {
                if (data) {
                    this.setSurveyYearKeys(data.surveyYearsModels);
                }

                resolve();
            });
        })
    }

    getMeasures(): any {
        return this.sections;
    }

    setMeasures(measures: any) {
        this.sections = measures;
    }

    loadMeasures(entityType: EntityType, surveyYearKey: number): Promise<void> {
        const emq: EntityMeasuresQuery = new EntityMeasuresQuery();

        switch (entityType) {
            case EntityType.SCHOOL:
                emq.entityType = 's';
                break;
            case EntityType.DISTRICT:
                emq.entityType = 'd';
                break;
            case EntityType.STATE:
                emq.entityType = 'st';
                break;
            case EntityType.NATIONAL:
                emq.entityType = 'n';
        }

        emq.entityId = entityType !== EntityType.NATIONAL ? this.entityId : 0;
        emq.surveyYearKey = surveyYearKey;

        return new Promise(resolve => {
            this.measureService.getEntityMeasures(emq).subscribe(data => {
                if (data) {
                    this.setMeasures(data.result);
                }

                resolve();
            });
        });
    }

    getStateIds(): State[] {
        return this.states;
    }

    setStateIds(states: State[]): void {
        this.states = states;
    }

    loadStateList(): Promise<void> {
        return new Promise(resolve => {
            this.statesService.getStatesList().subscribe(data => {
                if (data) {
                    this.setStateIds(data.statesModels);
                }

                resolve();
            });
        });
    }

    // This function encodes the following non-escaped characters including the existing encoding function characters ! ~ * ' ( )
    encodeCrdUri(uri: string) {
        let encodedUri: string = encodeURIComponent(uri);
        encodedUri = encodedUri.replace(/\!/g, '%21');
        encodedUri = encodedUri.replace(/\~/g, '%7E');
        encodedUri = encodedUri.replace(/\*/g, '%2A');
        encodedUri = encodedUri.replace(/\'/g, '%27');
        encodedUri = encodedUri.replace(/\(/g, '%28');
        encodedUri = encodedUri.replace(/\)/g, '%29');

        return encodedUri;
    }

    decodeCrdUri(uri: string) {
        let decodedUri: string = decodeURIComponent(uri);
        decodedUri = decodedUri.replace(/\%21/g, '!');
        decodedUri = decodedUri.replace(/\%7E/g, '~');
        decodedUri = decodedUri.replace(/\%2A/g, '*');
        decodedUri = decodedUri.replace(/\%27/g, '\'');
        decodedUri = decodedUri.replace(/\%28/g, '(');
        decodedUri = decodedUri.replace(/\%29/g, ')');

        return decodedUri;
    }

    routeTo404(): void {
        this.setNotFoundLink(window.location.href);
        this.router.navigateByUrl('/404');
    }

    convertToProfileBlockEnum(str: string): ProfileBlockTypes | undefined {
        const profileBlockType = ProfileBlockTypes[str as keyof typeof ProfileBlockTypes];
        return profileBlockType;
    }

    /**
     * Takes in a count of students and returns the proper formatting
     * for how it should be displayed in the front end should the
     * number be negative.
     * @param count given count of students
     * @returns a properly formatted string or number
     */
    getFormattedCount(count: number): any {
        if (count === -11) {
            return 'ǂ'
        } else if (count < 0 || count === null) {
            return '—';
        } else {
            return count;
        }
    }
}
