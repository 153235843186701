import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EntityType, State } from '../../models/profile-model';
import { DistrictProfileQueryModel } from '../../query-models/district-profile-query-model';
import { SchoolProfileQueryModel } from '../../query-models/school-profile-query-model';
import { ProfileService } from '../../services/helpers/profile.service';

@Component({
  selector: 'app-profile-report-hero',
  templateUrl: './profile-report-hero.component.html',
  styleUrls: ['./profile-report-hero.component.scss']
})
export class ProfileReportHeroComponent implements OnInit {

  @Input() entityType: EntityType;
  @Input() surveyYear: number;

  @Output() surveyYearUpdate: EventEmitter<number> = new EventEmitter<number>();
  @Output() viewSchools: EventEmitter<void> = new EventEmitter<void>();

  profile: any;

  public profileLoaded: boolean = false;

  schoolName: string;
  schoolCity: string;
  stateName: string;
  stateCode: string;
  gradesOffered: string;
  typeOfSchool: string;
  districtName: string;
  districtCity: string;
  totalSchools: string;
  totalDistricts: string;

  ncesId: string;
  combokey: string;
  leaId: string;
  stateId: number;
  studentsEnrolled: string;

  statePicture: string;
  states: State[];

  constructor(
    private readonly profileService: ProfileService,
    private router: Router) { }

  ngOnInit(): void {
    this.loadProfile();
  }

  getSchoolType(): string {
    let schoolType: string;

    const schoolTypes = [
      this.profile.isCharterSchool === '1' && 'Charter',
      this.profile.offersMagnetProgram === '1' && 'Magnet',
      this.profile.isAlternativeSchool === '1' && 'Alternative',
      this.profile.focusOnDisabilities === '1' && 'Special Education',
    ].filter(Boolean).join(' ') || 'Public';

    schoolType = this.profile.juvenileJustice ? 'Justice Facility' : `${schoolTypes} School`;

    if (this.profile.title1Status) {
      schoolType = 'Title I ' + schoolType;
    }

    return schoolType;
  }

  async loadProfile() {
    this.states = this.profileService.getStateIds();

    switch (this.entityType) {
      case EntityType.SCHOOL:
        this.profileLoaded = false;
        this.profile = JSON.parse(sessionStorage.getItem('schoolProfile'));

        let schoolProfile: SchoolProfileQueryModel = this.profile;
        let schoolState: State = this.states.find(state => state.state_Code === schoolProfile.schState);

        this.schoolName = schoolProfile.schName?.split('/').map((subString: string) => { return subString.trim() }).join(' / ');
        this.schoolCity = schoolProfile.schCity;
        this.stateName = schoolState.stateName;
        this.stateCode = schoolState.state_Code?.toLowerCase();
        this.districtName = schoolProfile.leaName;
        this.leaId = schoolProfile.leaId;
        this.ncesId = schoolProfile.longNcesId;
        this.combokey = schoolProfile.combokey;
        this.stateId = schoolProfile.stateId;
        this.gradesOffered = schoolProfile.gradesOffered?.replace(/\,/g, ', ');
        this.typeOfSchool = this.getSchoolType();
        this.studentsEnrolled = this.profileService.getFormattedCount(schoolProfile.totalEnrollment).toLocaleString('en-US');
        this.profileLoaded = true;
        break;
      case EntityType.DISTRICT:
        this.profileLoaded = false;
        this.profile = JSON.parse(sessionStorage.getItem('districtProfile'));
        let districtProfile: DistrictProfileQueryModel = this.profile;
        let districtState: State = this.states.find(state => state.state_Code === districtProfile.leaState);

        this.districtName = districtProfile.leaName?.split('/').map((subString: string) => { return subString.trim() }).join(' / ');
        this.districtCity = districtProfile.leaCity;
        this.stateCode = districtState.state_Code?.toLowerCase();
        this.stateName = districtState.stateName;
        this.leaId = districtProfile.leaId;
        this.stateId = districtState.state_Id;
        this.gradesOffered = districtProfile.gradesOffered?.replace(/\,/g, ', ');
        this.typeOfSchool = this.getSchoolType();
        this.studentsEnrolled = this.profileService.getFormattedCount(districtProfile.totalStudentCount).toLocaleString('en-US');
        this.profileLoaded = true;
        break;
      case EntityType.STATE:
        this.profileLoaded = false;
        this.profile = JSON.parse(sessionStorage.getItem('stateProfile'));
        let stateSpotlight = JSON.parse(sessionStorage.getItem('stateSpotlight'));

        this.stateCode = this.profile.state_Code;
        this.stateName = this.states.find(state => state.state_Code === this.stateCode).stateName;
        this.studentsEnrolled = this.profile.b_Count?.toLocaleString('en-US');
        this.totalDistricts = stateSpotlight.leAs?.toLocaleString('en-US');
        this.totalSchools = stateSpotlight.schools?.toLocaleString('en-US');
        this.statePicture = 'assets/us-states/' + this.stateName + '_State_AEM.png';
        this.profileLoaded = true;
        break;
      case EntityType.NATIONAL:
        this.profileLoaded = false;
        this.profile = JSON.parse(sessionStorage.getItem('nationalProfile'));
        let dataSpotlight = JSON.parse(sessionStorage.getItem('dataSpotlight'));

        this.studentsEnrolled = this.profile.b_Count?.toLocaleString('en-US');
        this.totalDistricts = dataSpotlight.leAs?.toLocaleString('en-US');
        this.totalSchools = dataSpotlight.schools?.toLocaleString('en-US');
        this.statePicture = 'assets/us-states/us_map.png';
        this.profileLoaded = true;
        break;
      default:
        throw new Error("Unknown entity type.");
    }
  }

  updateSurveyYear(year: number) {
    let url: string = this.router.url;
    let params: string[] = url.split('?');
    let currentRoute: string = params[0];
    let queryParams: string[] = params[1].split('&');
    let ncesId: string = queryParams.length === 2 ? `&${queryParams[1]}` : '';
    let newYear: string = this.profileService.getSurveyYearKeys(EntityType.GENERAL).find(surveyYear => surveyYear.yearKey === year).surveyYear;
    let newRoute: string = currentRoute + `?surveyYear=${newYear}${ncesId}`;

    this.router.navigateByUrl(newRoute);
    this.surveyYearUpdate.emit(year);
  }

  onViewSchools() {
    this.viewSchools.emit();
  }
}
