<div cdkCustomScrollable class="faq-page-container">
    <app-hero heroHeader="Frequently Asked Questions" heroSubHeader="About"
        heroDescription="Find answers about the Civil Right Data Collection as well as the civil rights data featured on this site."
        heroImageSrc="assets/images/home_hero_img.png"
        heroImageAlt="older students in a classroom with one student standing" paddingTop="2.75rem"
        paddingBottom="2.5rem">
    </app-hero>
    <div class="faq-content">
        <div class="faq-container is-flex is-flex-direction-row is-justify-content-center">
            <div *ngIf="screenType !== ScreenType.MOBILE" class="contents-container">
                <div class="contents is-flex is-flex-direction-column">
                    <h5 class="faq-section has-text-primary">Contents</h5>
                    <p [ngStyle]="{'font-weight': question1FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question1)">What is the Civil Rights Data Collection?</p>
                    <p [ngStyle]="{'font-weight': question2FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question2)">What is the purpose of the CRDC?</p>
                    <p [ngStyle]="{'font-weight': question3FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question3)">Under what authority does ED conduct the CRDC?</p>
                    <p [ngStyle]="{'font-weight': question4FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question4)">What is different in the 2020-21 CRDC?</p>
                    <p [ngStyle]="{'font-weight': question5FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question5)">Which data for the CRDC are being collected through
                        other ED collections?</p>
                    <p [ngStyle]="{'font-weight': question6FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question6)">Are privacy protections applied to the data before it is
                        released to the public? </p>
                    <p [ngStyle]="{'font-weight': question7FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question7)">How can I submit a CRDC data quality concern to OCR?</p>
                    <p [ngStyle]="{'font-weight': question8FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question8)">How can I find out more about the CRDC and OCR?
                    </p>
                    <p [ngStyle]="{'font-weight': question9FontWeight}" class="faq-section body has-text-primary"
                        (click)="customScroll(question9)">How do I file a civil rights complaint?</p>
                </div>
            </div>
            <div *ngIf="screenType !== ScreenType.MOBILE" class="vl"></div>
            <app-modal *ngIf="screenType === ScreenType.MOBILE" class="modal-container" [isClicked]="isClicked"
                (isClosed)="this.clickQuestion()">
                <div class="contents-container">
                    <div class="contents is-flex is-flex-direction-column">
                        <h5 class="faq-section has-text-primary">Contents</h5>
                        <p [ngStyle]="{'font-weight': question1FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question1)">What is the Civil Rights Data Collection?</p>
                        <p [ngStyle]="{'font-weight': question2FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question2)">What is the purpose of the CRDC?</p>
                        <p [ngStyle]="{'font-weight': question3FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question3)">Under what authority does ED conduct the CRDC?</p>
                        <p [ngStyle]="{'font-weight': question4FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question4)">What is different in the 2020-21 CRDC?</p>
                        <p [ngStyle]="{'font-weight': question5FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question5)">Which data for the CRDC are being collected through
                            other ED collections?</p>
                        <p [ngStyle]="{'font-weight': question6FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question6)">Are privacy protections applied to the data before it is
                            released to the public? </p>
                        <p [ngStyle]="{'font-weight': question7FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question7)">How can I submit a CRDC data quality concern to OCR?</p>
                        <p [ngStyle]="{'font-weight': question8FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question8)">How can I find out more about the CRDC and OCR?
                        </p>
                        <p [ngStyle]="{'font-weight': question9FontWeight}" class="faq-section body has-text-primary"
                            (click)="customScroll(question9)">How do I file a civil rights complaint?</p>
                    </div>
                </div>
            </app-modal>
            <div class="faq-content is-flex is-flex-direction-column">
                <div #question1 id="question1" class="question">
                    <h4 class="question-header has-text-primary">What is the Civil Rights Data Collection?</h4>
                    <p class="large-body has-text-primary">
                        The Civil Rights Data Collection (CRDC) is generally a biennial (i.e., every other school year)
                        survey required by the U.S. Department of Education's (ED) Office for Civil Rights (OCR). The
                        CRDC collects data from a universe of all public local educational agencies (LEA) and schools,
                        including justice facilities, charter schools, and alternative schools.
                    </p>
                </div>
                <div #question2 id="question2" class="question">
                    <h4 class="question-header has-text-primary">What is the purpose of the CRDC?</h4>
                    <p class="large-body has-text-primary">
                        The CRDC collects data on leading civil rights indicators related to access and barriers to
                        educational opportunities at the early childhood through grade 12 levels. OCR relies on the CRDC
                        data it receives from public school districts as it investigates complaints alleging
                        discrimination, determines whether the Federal civil rights laws it enforces have been violated,
                        initiates proactive compliance reviews, and provides policy guidance and technical assistance to
                        schools and school districts.
                    </p>
                </div>
                <div #question3 id="question3" class="question">
                    <h4 class="question-header has-text-primary">Under what authority does ED conduct the CRDC?
                    </h4>
                    <p class="large-body has-text-primary">
                        The Civil Rights Data Collection (CRDC) is a mandatory data collection that schools and school
                        districts that received Federal financial assistance from the Department are legally obligated
                        to complete.<br><br>Section 203(c)(1) of the 1979 Department of Education Organization Act
                        (DEOA) conveys to the Assistant Secretary for Civil Rights the authority to “collect or
                        coordinate the collection of data necessary to ensure compliance with civil rights laws within
                        the jurisdiction of the Office for Civil Rights.” See 20 U.S.C. § 3413(c)(1).<br><br>The civil
                        rights laws enforced by OCR for which the CRDC collects data include Title VI of the Civil
                        Rights Act of 1964, which prohibits discrimination based on race, color, or national origin;
                        Title IX of the Education Amendments of 1972, which prohibits discrimination based on sex; and
                        Section 504 of the Rehabilitation Act of 1973, which prohibits discrimination on the basis of
                        disability. OCR's implementing regulations for each of these statutes require recipients of ED's
                        Federal financial assistance to submit to OCR “complete and accurate compliance reports at such
                        times, and in such form and containing such information” as OCR “may determine to be necessary
                        to enable [OCR] to ascertain whether the recipient has complied or is complying” with these laws
                        and implementing regulations. See 34 C.F.R. § 100.6(b) (incorporated by reference into 34
                        C.F.R. §§ 104.61 and 106.81).
                    </p>
                </div>
                <div #question4 id="question4" class="question">
                    <h4 class="question-header has-text-primary">What is different in the 2020-21 CRDC?</h4>
                    <div class="content">
                        <p class="large-body has-text-primary">
                            The 2020-21 CRDC is similar to the 2017-18 CRDC previously approved by the Office
                            of Management and Budget (OMB) in October 2017. Some changes were made to the
                            CRDC, and those changes had the net effect of reducing burden on school districts. For
                            example, the CRDC collected additional data on sexual violence and harassment or
                            bullying, but it no longer collected data on school finance, teacher experience, and
                            teacher absenteeism.<br><br>
                            OMB approved the changes to the CRDC under the Paperwork Reduction Act on
                            December 28, 2020, under Control Number 1870-0504. All the documentation
                            submitted to OMB can be found at <a
                                href="https://www.reginfo.gov/public/do/PRAViewICR?ref_nbr=201909-1870-001"
                                target="_blank" rel="noreferrer noopener">this link</a>.<br><br>
                            The majority of the data elements that were previously collected for the 2017-18 CRDC
                            continued to be collected for the 2020-21 CRDC. A complete list of data elements
                            included in the 2020-21 CRDC is found <a
                                href="assets/downloads/2020-21-crdc-data-elements.pdf" target="_blank"
                                rel="noreferrer noopener">here</a>.<br><br>
                            The following data element is new and required for the 2020-21 CRDC:
                        </p>
                        <ul>
                            <li class="large-body has-text-primary">Number of preschool students who received one or
                                more out-of-school suspension (disaggregated by race, sex, disability-IDEA, EL).</li>
                        </ul>
                        <p class="large-body has-text-primary">The following data elements are new and optional for the
                            2020-21 CRDC:</p>
                        <ul>
                            <li class="large-body has-text-primary">Number of documented incidents of offenses committed
                                by a student that occurred at the school. Offenses categories include: rape or attempted
                                rape, and sexual assault (other than rape).
                            </li>
                            <li class="large-body has-text-primary">Number of documented incidents of offenses committed
                                by a school staff member that occurred at the school. Offenses categories include: rape
                                or attempted rape, and sexual assault (other than rape).
                            </li>
                            <li class="large-body has-text-primary">Number of allegations made against a school staff
                                member of offenses that occurred at the school, which were followed by a resignation or
                                retirement prior to final discipline or termination. Offenses categories include: rape
                                or attempted rape, and sexual assault (other than rape).
                            </li>
                            <li class="large-body has-text-primary">Number of allegations made against a school staff
                                member of offenses that occurred at the school, which were followed by a determination
                                that the school staff member was responsible for the offense. Offenses categories
                                include: rape or attempted rape, and sexual assault (other than rape).
                            </li>
                            <li class="large-body has-text-primary">Number of allegations made against a school staff
                                member of offenses that occurred at the school, which were followed by a determination
                                that the school staff member was not responsible for the offense. Offenses categories
                                include: rape or attempted rape, and sexual assault (other than rape).
                            </li>
                            <li class="large-body has-text-primary">Number of allegations made against a school staff
                                member of offenses that occurred at the school, which had a determination that remained
                                pending. Offenses categories include: rape or attempted rape, and sexual assault (other
                                than rape).
                            </li>
                            <li class="large-body has-text-primary">Number of allegations made against a school staff
                                member of offenses that occurred at the school, which were followed by a duty
                                reassignment prior to final discipline or termination. Offenses categories include: rape
                                or attempted rape, and sexual assault (other than rape).
                            </li>
                            <li class="large-body has-text-primary">Number of reported allegations of harassment or
                                bullying of K-12 students on the basis of perceived religion (disaggregated by
                                atheism/agnosticism; Buddhist; Catholic; Eastern Orthodox; Hindu; Islamic (Muslim);
                                Jehovah's Witness; Jewish; Mormon; multiple religions, group; other Christian; other
                                religion; Protestant; Sikh).
                            </li>
                            <li class="large-body has-text-primary">Number of students enrolled in the International
                                Baccalaureate (IB) Diploma Programme (disaggregated by <u>disability-504 only</u>).
                            </li>
                            <li class="large-body has-text-primary">Number of students enrolled in at least one AP
                                course (disaggregated by <u>disability-504 only</u>).
                            </li>
                        </ul>
                        <p class="large-body has-text-primary">The following data elements were dropped for the 2020-21
                            CRDC:</p>
                        <ul>
                            <li class="large-body has-text-primary">Number of EL students enrolled in EL programs
                                (disaggregated by disability-IDEA).
                            </li>
                            <li class="large-body has-text-primary">Number of preschool students who received one
                                out-of-school suspension (disaggregated by race, sex, disability-IDEA, EL).
                            </li>
                            <li class="large-body has-text-primary">Number of preschool students who received more than
                                one out-of-school suspension (disaggregated by race, sex, disability-IDEA, EL).
                            </li>
                            <li class="large-body has-text-primary">Number of documented incidents of:
                                <ul style="list-style-type:circle;">
                                    <li class="large-body has-text-primary">Robbery with a firearm or explosive device.
                                    </li>
                                    <li class="large-body has-text-primary">Physical attack or fight with a firearm or
                                        explosive device.
                                    </li>
                                    <li class="large-body has-text-primary">Threat of physical attack with a firearm or
                                        explosive device.
                                    </li>
                                </ul>
                            </li>
                            <li class="large-body has-text-primary">Whether an LEA has a web link to policy or policies
                                prohibiting harassment or bullying of students on the basis of all of the following:
                                sex; race, color, or national origin; disability (LEA).
                            </li>
                            <li class="large-body has-text-primary">Whether LEA's early childhood program(s) serve
                                non-IDEA children age birth to 2 years (LEA).
                            </li>
                            <li class="large-body has-text-primary">Whether preschool is provided to: all students,
                                students with disabilities (IDEA), students in Title I schools, students from low income
                                families (LEA).
                            </li>
                            <li class="large-body has-text-primary">Whether preschool serves non-IDEA students age 3
                                years; age 4 years; age 5 years (LEA).
                            </li>
                            <li class="large-body has-text-primary">Whether the school's preschool program serves
                                non-IDEA students age 3 years; age 4 years; age 5 years.
                            </li>
                            <li class="large-body has-text-primary">Preschool length offered (full-day, part-day) and
                                cost (free, partial charge, full charge) (LEA).
                            </li>
                            <li class="large-body has-text-primary">Kindergarten length offered (full-day, part-day) and
                                cost (free, partial charge, full charge) (LEA).
                            </li>
                            <li class="large-body has-text-primary">Number of students who participate in at least one
                                credit recovery program that allows them to earn missed credit to graduate from high
                                school.
                            </li>
                            <li class="large-body has-text-primary">Number of students enrolled in at least one AP
                                course in other AP subjects of any kind (including foreign language) (disaggregated by
                                race, sex, disability, EL).
                            </li>
                            <li class="large-body has-text-primary">Number of students who took one or more AP exams for
                                one or more (which may include all) AP courses enrolled in (disaggregated by race, sex,
                                disability-IDEA, EL).
                            </li>
                            <li class="large-body has-text-primary">Number of students who were enrolled in one or more
                                AP courses but who did not take any AP exams (disaggregated by race, sex,
                                disability-IDEA, EL).
                            </li>
                            <li class="large-body has-text-primary">K-12 personnel FTEs and salaries at the school level
                                (funded with state and/or local funds):
                                <ul style="list-style-type:circle;">
                                    <li class="large-body has-text-primary">Number of FTE teachers and amount of their
                                        salaries.
                                    </li>
                                    <li class="large-body has-text-primary">Number of FTE instructional aides and amount
                                        of
                                        their salaries.
                                    </li>
                                    <li class="large-body has-text-primary">Number of FTE support services staff (for
                                        pupils
                                        and for instructional staff) and amount of their salaries.
                                    </li>
                                    <li class="large-body has-text-primary">Number of FTE school administration staff
                                        and
                                        amount of their salaries.
                                    </li>
                                    <li class="large-body has-text-primary">Total amount of total personnel
                                        (instructional,
                                        support services, and school administration) salaries.
                                    </li>
                                </ul>
                            </li>
                            <li class="large-body has-text-primary">Preschool-12 personnel FTEs and salaries at the
                                school level (funded with federal, state, and/or local funds):
                                <ul style="list-style-type:circle;">
                                    <li class="large-body has-text-primary">Amount of teacher salaries.
                                    </li>
                                    <li class="large-body has-text-primary">Number of FTE instructional aides and amount
                                        of
                                        their salaries.
                                    </li>
                                    <li class="large-body has-text-primary">Number of FTE support services staff (for
                                        pupils
                                        and for instructional staff) and amount of their salaries.
                                    </li>
                                    <li class="large-body has-text-primary">Number of FTE school administration staff
                                        and
                                        amount of their salaries.
                                    </li>
                                    <li class="large-body has-text-primary">Total amount of total personnel
                                        (instructional,
                                        support services, and school administration) salaries.
                                    </li>
                                </ul>
                            </li>
                            <li class="large-body has-text-primary">Total amount of non-personnel expenditures at the
                                school level:
                                <ul style="list-style-type:circle;">
                                    <li class="large-body has-text-primary">Amount of non-personnel expenditures (funded
                                        with state and/or local funds).
                                    </li>
                                    <li class="large-body has-text-primary">Amount of non-personnel expenditures (funded
                                        with federal, state, and/or local funds).
                                    </li>
                                </ul>
                            </li>
                            <li class="large-body has-text-primary">Number of FTE first-year teachers.
                            </li>
                            <li class="large-body has-text-primary">Number of FTE second-year teachers.
                            </li>
                            <li class="large-body has-text-primary">Number of FTE teachers absent more than 10 school
                                days (excluding professional development).
                            </li>
                            <li class="large-body has-text-primary">Number of current school year teachers.
                            </li>
                            <li class="large-body has-text-primary">Number of current and previous school year teachers.
                            </li>
                        </ul>
                    </div>
                </div>
                <div #question5 id="question5" class="question">
                    <h4 class="question-header has-text-primary">Which data for the CRDC are being collected through
                        other ED collections?</h4>
                    <p class="large-body has-text-primary">
                        To reduce the burden on LEAs, beginning with the 2009-10 CRDC, OCR leveraged data submitted to
                        ED by state educational agencies (SEA). Specifically, OCR removed from the CRDC data collected
                        by both the CRDC and ED<i>Facts</i> collections, i.e., school-level data on the number of
                        students served under the <i>Individuals with Disabilities Education Act (IDEA)</i> by
                        disability category or educational environment, and school-level data on the number of high
                        school completers. Beginning with the 2009-10 CRDC, ED merged ED<i>Facts</i> data with the CRDC
                        data set to provide more complete and robust data for civil rights purposes, but with no
                        additional burden on LEAs. Beginning with the 2017-18 CRDC, OCR stopped collecting student
                        chronic absenteeism data. ED began collecting that school-level data from SEAs through the
                        ED<i>Facts</i> collection, beginning with data for the 2016-17 school year. Beginning with the
                        2020-21 CRDC, OCR removed school expenditures data elements from the CRDC. OCR is collaborating
                        with ED's National Center for Education Statistics to make data elements in the 2021-22 and
                        future school years' School-level Finance Survey (SLFS) mandatory. The SLFS expenditures data
                        elements are similar to the former school-level expenditures data elements in OCR's 2017-18
                        CRDC. For the CRDC, OCR will continue to leverage school-level data submitted through other ED
                        collections to reduce the burden on LEAs.
                    </p>
                </div>
                <div #question6 id="question6" class="question">
                    <h4 class="question-header has-text-primary">Are privacy protections applied to the data before it
                        is released to the public?</h4>
                    <p class="large-body has-text-primary">
                        Yes. To prevent the disclosure of identifying reported values, most data made available to the
                        public have been privacy-protected by making small, random adjustments to the data. To learn
                        more about the CRDCs' privacy methodologies, please see the <a routerLink="/data"
                            target="_blank" rel="noopener noreferrer">CRDCs' data file user's manuals and data
                            notes</a>.
                    </p>
                </div>
                <div #question7 id="question7" class="question">
                    <h4 class="question-header has-text-primary">How can I submit a CRDC data quality concern to OCR?
                    </h4>
                    <p class="large-body has-text-primary">
                        The CRDC data can be accessed at <a href="https://civilrightsdata.ed.gov" target="_blank"
                            rel="noreferrer noopener">https://civilrightsdata.ed.gov</a>. If anyone has concerns about
                        the accuracy of the data reported, then please contact OCR by emailing <a
                            href="mailto:ocrdata@ed.gov">ocrdata@ed.gov</a>. Please include “CRDC Data Quality
                        Concern” in the subject heading.
                    </p>
                </div>
                <div #question8 id="question8" class="question">
                    <h4 class="question-header has-text-primary">How can I find out more about the CRDC and OCR?</h4>
                    <p class="large-body has-text-primary">
                        For more information about the CRDC and OCR, please visit: <a href="http://crdc.ed.gov"
                            target="_blank" rel="noreferrer noopener">http://crdc.ed.gov</a>.
                    </p>
                </div>


                <div #question9 id="question9" class="question">
                    <h4 class="question-header has-text-primary">How do I file a civil rights complaint?</h4>
                    <p class="large-body has-text-primary">
                        To file a complaint, go to <a href="https://ocrcas.ed.gov/contact-ocr" target="_blank"
                            rel="noreferrer noopener">https://ocrcas.ed.gov/contact-ocr</a>.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
