import { Injectable } from '@angular/core';
import { HttpEvent, HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(
        req: HttpRequest<any>, next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                //this.router.navigateByUrl('/clienterror');
                //let errMsg = '';

                //if (error.error instanceof ErrorEvent) {
                //    errMsg = `Error: ${error.error.message}`;
                //} else {
                //    errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                //}

                return throwError(error);;
            }) as any
        );
    }

}
