import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { MessageService } from '../core/message.service';

import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';

import { SchoolProfileQueryModel } from '../../query-models/school-profile-query-model';
import { EntityDataQuery } from '../../queries/entity-data-query';

@Injectable()
export class SchoolProfileService extends BaseService {

  private apiUrl = environment.api_url; // URL to web api

  constructor(
    private http: HttpClient,
    messageService: MessageService,
    dataService: DataService) {
        super(messageService, dataService);
    }

    /** GET **/
    get(query: EntityDataQuery): Observable<SchoolProfileQueryModel> {

        this.setHeaders();

        const url = this.apiUrl + 'GetSchoolProfile';

        var params = new HttpParams();
        params = params.append('survey_Year_Key', query.surveyYearKey.toString());
        params = params.append('Entity_Id', query.entityId.toString());
        params = params.append('School_Or_District', "s");

        return this.http.get<SchoolProfileQueryModel>(
        url,
        {
            params: params,
            observe: 'response'
        })
        .pipe(
            map(resp => {
                if (resp != undefined || resp != null) {
                    return resp.body;
                }

            return null;
            }),
            tap(resp => {
                this.log(`fetched`);
            }),
            catchError(this.handleError('get', null))
      );
    }
}
