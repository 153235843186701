<div class="home-container">
    <div class="home-hero-container is-flex is-flex-direction-column is-justify-content-center">
        <div class="hero-primary is-flex is-flex-direction-row is-justify-content-center">
            <div class="hero-header-container is-flex is-align-self-center is-flex-direction-column">
                <h1 class="hero-header has-text-left">Data on Equal Access to Education</h1>
                <div class="hero-content-container">
                    <hr class="hero-line">
                    <div class="hero-text large-body">
                        For more than five decades, the Civil Rights Data Collection has captured data on students'
                        equal access to educational opportunities to understand and inform schools' compliance with the
                        civil rights laws enforced by the Department of Education's Office for Civil rights.
                    </div>
                    <button class="learn-more-button button is-rounded primary-button is-regular-button"
                        routerLink="/about" style="text-decoration: none;">Learn More</button>
                </div>
            </div>
            <div class="video-container" *ngIf="screenType === ScreenType.DESKTOP">
                <video class="rounded-corners" width="650px" style="color:white"
                    title="Teacher reads a story to her excited students" autoplay loop muted>
                    <source src="assets/videos/home_hero_video.mp4" type="video/mp4">
                    Your browser does not support the embedded videos.
                </video>
            </div>
        </div>

        <hr class="hero-seperator">

        <div class="hero-secondary is-flex is-flex-direction-row is-justify-content-space-between">
            <h3 class="secondary-header has-text-white">About the Office for Civil Rights</h3>
            <div class="secondary-summary">
                <p class="summary-text large-body">
                    The Office for Civil Rights (OCR) administers the Civil Rights Data Collection (CRDC), a mandatory
                    survey of all public school districts and schools in the 50 states, Washington, D.C., and the
                    Commonwealth of Puerto Rico.
                </p>
                <button class="button is-rounded is-outlined secondary-outline-button is-regular-button"
                    routerLink="/about/ocr" style="text-decoration: none;">
                    Learn More
                </button>
            </div>
        </div>
    </div>

    <img src="assets/images/squiggly-lines.png" alt="" style="margin-bottom:-.5rem">

    <div class="content-container-background is-flex is-flex-direction-column is-align-content-center">
        <div class="content-container">
            <div class="home-chart-container is-flex is-flex-direction-column">
                <div class="home-chart">
                    <div class="title-container is-flex is-flex-direction-row is-justify-content-space-between">
                        <h4 class="has-text-primary" style="padding-right:1rem;z-index:2000;">Featured 2020-21 Data:
                            Algebra I Across the Nation<sup>1</sup></h4>
                        <div fieldset class="is-flex is-flex-direction-column" style="gap: 10px">
                            <legend>
                                <p class="body has-text-primary">Select Grades</p>
                            </legend>
                            <mat-form-field class="algebra-dropdown" floatLabel="never" [style.height.rem]=2
                                [style.width.rem]=7.5>
                                <mat-select [(ngModel)]="selectedChart" name="chart">
                                    <mat-option style="color:#133559" *ngFor="let chart of charts" [value]="chart">
                                        {{ chart.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button class="button is-rounded is-outlined outline-button is-regular-button"
                                (click)="downloadExcelFile()">Download Chart Data</button>
                        </div>
                    </div>
                    <img [src]="selectedChart.src" [alt]="selectedChart.alt" width="1440px" />
                </div>
            </div>

            <div class="data-fueled-change-container is-flex is-flex-direction-row is-justify-content-center">
                <div class="is-flex is-flex-direction-column is-align-self-flex-start">
                    <p class="small-header large-body has-text-primary">What We Do</p>
                    <h2 class="has-text-primary">Using the Data in the CRDC</h2>
                </div>
                <div class="about-info-container is-flex is-flex-direction-column is-align-items-flex-start">
                    <div class="about-text large-body has-text-primary">OCR uses data from the CRDC to inform
                        investigations, policy guidance and technical assistance to school districts and
                        schools.<br><br>Other ED offices and federal agencies, policymakers and researchers, educators
                        and school officials, parents and students, and the public also use the CRDC as a valuable
                        resource for understanding students' access to educational opportunities in our nation's public
                        schools.
                    </div>
                    <button class="button is-rounded is-outlined outline-button is-regular-button"
                        routerLink="/about/crdc">About
                        CRDC</button>
                </div>
                <div class="tile is-parent is-1 is-hidden-mobile"></div>
            </div>

            <hr class="home-body-line is-align-self-center">

            <div class="content-block is-flex is-flex-direction-row is-justify-content-center">
                <div class="content-block-image is-hidden-mobile is-flex">
                    <img class="rounded-corners is-align-self-center" src="assets/images/secondary-content.png"
                        alt="young child reading a book on the floor of a bedroom" />
                </div>

                <div
                    class="secondary-content is-align-self-center is-flex is-flex-direction-column is-align-items-flex-start">
                    <p class="content-block-sub-header large-body has-text-primary">What's New?</p>
                    <h4 class="content-block-header has-text-primary">The 2020-21 Data Collection</h4>
                    <hr class="content-divider is-hidden-touch">
                    <p class="content-block-text has-text-black body">
                        The 2020-21 Civil Rights Data Collection (CRDC) contains most of the data elements that were
                        previously collected in the 2017-18 CRDC. In addition, some new data elements were optional for
                        the 2020-21 collection.
                    </p>
                    <button class="report-button button is-rounded primary-button is-regular-button"
                        (click)="routeAndScroll()">See What's New</button>
                </div>
            </div>

            <div *ngIf="blocksLoaded" class="data-container">
                <div class="profile-block is-flex is-flex-direction-column is-aling-items-center" *ngFor="let profileBlock of profileBlocks; let i = index">
                    <app-profile-block [direction]="i % 2 === 0 ? 'right' : 'left'" [id]="profileBlock.blockType" [profileBlock]="profileBlock" class="profile-block">
                    </app-profile-block>
                </div>
            </div>
        </div>
    </div>

    <app-home-featured-reports></app-home-featured-reports>
</div>
