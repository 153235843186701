import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enforcement-data-tool',
  templateUrl: './enforcement-data-tool.component.html',
  styleUrls: ['./enforcement-data-tool.component.scss']
})
export class EnforcementDataToolComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (environment.production) {
      this.router.navigate(['']);
    }
  }
}
