import { EventEmitter, Injectable } from '@angular/core';
import { EntitySearchResult } from '../../query-models/entity-search-model';

@Injectable({
  providedIn: 'root'
})
export class ComparisonService {

  readonly maxSchools: number = 20;

  comparisonUpdate: EventEmitter<boolean> = new EventEmitter();

  comparisonReport: Array<EntitySearchResult> = [];

  constructor() {
    this.comparisonReport = [
      { entity_Name: 'Autaga High School', entity_Type: 'Schools', entity_ID: 12, syk: '2019' },
      { entity_Name: 'Baldwin High School', entity_Type: 'Schools', entity_ID: 12, syk: '2019' },
      { entity_Name: 'Daphne High School', entity_Type: 'Schools', entity_ID: 12, syk: '2019' },
      { entity_Name: 'Elberta High School', entity_Type: 'Schools', entity_ID: 12, syk: '2019' }
    ];
  }

  getReportCount(): number {
    return this.comparisonReport.length;
  }

  getCompareItems(): EntitySearchResult[] {
    return this.comparisonReport;
  }

  addCompareItem(item: EntitySearchResult): void {
    this.comparisonReport.push(item);
    this.comparisonUpdate.emit(true);
  }

  removeCompareItem(item: EntitySearchResult): void {
    const itemIndex: number = this.comparisonReport.indexOf(item);
    this.comparisonReport.splice(itemIndex, 1);
    this.comparisonUpdate.emit(true);
  }
}
