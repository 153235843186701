<nav class="side-nav is-flex is-flex-direction-column is-justify-content-left">
    <div class="comparison-container is-flex is-flex-direction-row is-justify-content-space-between">
        <div class="is-flex is-flex-direction-row">
            <img src="assets/images/compare.svg" class="filter-navy-blue" width="22.75px" height="21.12px"  alt="Compare report"/>
            <p class="comparison-text has-text-primary">Data Comparison Tool</p>
        </div>
        <button mat-icon-button (click)="closeSideNav()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>
    <div class="school-divider is-justify-content-left">
        <p class="schools-text has-text-primary">Items</p>
    </div>
    <div class="content">
        <ol class="list">
            <li *ngFor="let item of itemsArray" class="school-text has-text-primary">
                <div class="is-flex is-flex-direction-row is-justify-content-space-between">
                    <p class="school-text has-text-primary">{{ item.entity_Name }}</p>
                    <button class="more-info-button" mat-icon-button (click)="removeCompareItem(item)">
                        Remove
                    </button>
                </div>
            </li>
        </ol>
    </div>
    <hr *ngIf="itemsArray.length > 0" class="bottom-divider">
    <div class="button-container is-flex-direction-row">
        <button class="generate-report-button button is-rounded is-outlined primary-button is-regular-button" routerLink="data-tool">Generate
            Report</button>
    </div>
</nav>
