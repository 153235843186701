import { HttpClient, HttpHeaders, HttpHandler } from '@angular/common/http';

import { MessageService } from './message.service';
import { Observable, of } from 'rxjs';
import { WebServiceError } from '../../value-objects/web-service-error';
import { DataService } from './data.service';

export abstract class BaseService {

    protected headers: HttpHeaders = new HttpHeaders();

    constructor(
        private messageService: MessageService,
        private dataService: DataService
    ) {

    }

    protected setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');
        //this.headers = this.headers.set("Access-Control-Allow-Origin", "*");
        //this.headers = this.headers.set("Access-Control-Allow-Methods", "GET, POST, DELETE, PUT");
        //this.headers = this.headers.set("Access-Control-Allow-Headers", "Origin, Methods, Content-Type");
    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // Ignore 404 errors (these should not be logged as they just indicate queries that do not return any data)
            if (error.status != 404) {

                // Store web service error in shared service
                var webServiceError = new WebServiceError();

                if (error.error && error.error.message) {
                    webServiceError.message = error.error.message;
                    webServiceError.exceptionType = error.error.exceptionType;
                    webServiceError.exceptionMessage = error.error.exceptionMessage;
                    webServiceError.stackTrace = error.error.stackTrace;
                    webServiceError.validationFailures = error.error.validationFailures;

                } else {
                    webServiceError.message = error.message;
                }

                // TODO: send the error to remote logging infrastructure
                console.error(webServiceError); // log to console instead

                // TODO: better job of transforming error for user consumption
                this.log(`${operation} failed: ${webServiceError.exceptionMessage}`);

                this.dataService.setWebServiceError(webServiceError);

            }

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a message with the Service */
    protected log(message: string) {
        this.messageService.add('Service: ' + message);
    }

}
