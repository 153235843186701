import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';

// Services
import { ComparisonService } from '../../services/helpers/comparison.service';
import { EntitySearchResult } from '../../query-models/entity-search-model';


@Component({
    selector: 'side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
    
    @Output() closePressed = new EventEmitter();
    itemsArray: EntitySearchResult[];

    comparisonSubscription: Subscription;

    isPhone$: Observable<boolean> = this.breakpointObserver.observe('( max-width: 767px )')
        .pipe(
            map(result => result.matches),
            shareReplay()
    );

    showMobileNav = new BehaviorSubject<boolean>(false);

    constructor(
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private comparisonService: ComparisonService
    ) { }

    ngOnInit(): void {
        this.isPhone$.subscribe(isPhone => {
            if (!isPhone) { this.closeMobileMenu(); }
        });

        this.router.events.subscribe(() => {
            this.closeMobileMenu();
        });

        this.getCompareItems();
    }

    ngOnDestroy(): void {
        this.comparisonSubscription.unsubscribe();
    }

    getCompareItems(): void {
        this.itemsArray = this.comparisonService.getCompareItems();

        this.comparisonSubscription = this.comparisonService.comparisonUpdate.subscribe((event) => {
            this.itemsArray = this.comparisonService.getCompareItems();
        })
    }

    closeMobileMenu() {
        this.showMobileNav.next(false);
    }

    closeSideNav() {
        this.closePressed.emit(true);
    }

    removeCompareItem(item: EntitySearchResult) {
        this.comparisonService.removeCompareItem(item);
    }
}
