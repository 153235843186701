import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Container
import { EstimationsComponent } from './estimations.component';

// Components
import { Estimations20132014Component } from './2013-2014/estimations-2013-2014.component';
import { Estimations20112012Component } from './2011-2012/estimations-2011-2012.component';
import { Estimations20092010Component } from './2009-2010/estimations-2009-2010.component';
import { Estimations2006Component } from './2006/estimations-2006.component';
import { Estimations2004Component } from './2004/estimations-2004.component';
import { Estimations2000Component } from './2000/estimations-2000.component';
import { Estimations20152016Component } from './2015-2016/estimations-2015-2016.component';
import { Estimations20172018Component } from './2017-2018/estimations-2017-2018.component';
import { Estimations20202021Component } from './2020-2021/estimations-2020-2021.component';
import { NotFoundComponent } from './../../shared/components/not-found/not-found.component';
import { UrlGuard } from '../../shared/guards/url-guard';



const featureRoutes: Routes = [
    {
        path: '2000',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations2000Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2000 Estimations | Civil Rights Data',
            description: 'View estimations for the 2000 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '2004',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations2004Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2004 Estimations | Civil Rights Data',
            description: 'View estimations for the 2004 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '2006',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations2006Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2006 Estimations | Civil Rights Data',
            description: 'View estimations for the 2006 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '2009-2010',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations20092010Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2009-10 Estimations | Civil Rights Data',
            description: 'View estimations for the 2009-10 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '2011-2012',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations20112012Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2011-12 Estimations | Civil Rights Data',
            description: 'View estimations for the 2011-12 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '2013-2014',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations20132014Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2013-14 Estimations | Civil Rights Data',
            description: 'View estimations for the 2013-14 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '2015-2016',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations20152016Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2015-16 Estimations | Civil Rights Data',
            description: 'View estimations for the 2015-16 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '2017-2018',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations20172018Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2017-18 Estimations | Civil Rights Data',
            description: 'View estimations for the 2017-18 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '2020-2021',
        component: EstimationsComponent,
        pathMatch: "full",
        children: [
            { path: '', component: Estimations20202021Component }
        ],
        canActivate: [UrlGuard],
        data: {
            title: '2020-21 Estimations | Civil Rights Data',
            description: 'View estimations for the 2020-21 data collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '',
        pathMatch: "full",
        redirectTo: "2000",
    },
    // not found page
    { path: 'notfound', component: NotFoundComponent },
    { path: '**', redirectTo: 'notfound' }

];


@NgModule({
    imports: [RouterModule.forChild(featureRoutes)],
    exports: [RouterModule]
})
export class EstimationsRoutingModule { }
